import React, { useEffect, useState } from 'react'
import { Footer, Header, HeaderBox } from '../../components/imports'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Func from '../../class/Func'
import { APP_NAME } from '../../constants/general'
import { useTranslation } from 'react-i18next'

function LivraisonRefuse() {

  const {t} = useTranslation()

  const id = useParams()

  const navigate = useNavigate()

  const [reclamation, setReclamation] = useState(0)
  const [reclamationText, setReclamationText] = useState('')

  const envoyerReclamation = async (e) => {

    e.preventDefault()
    
    const results = await Func.fetch("annonces/commandes/livraisonRefusee", {id: id.id, reclamation, reclamationText})

    if(results.success){

      Func.alert(t('373'))

      navigate('/commandes/0')

    }else{

      Func.alert(results.message)

    }

  }

  useEffect(() => {

    document.title = t('372') + " - " + APP_NAME

  }, [])

  return (
    <div>
      <Header/>

      <HeaderBox/>

      <div className='c flex ai-center jc-center mb30'>

        <form method="POST" className="form mt50 brca p50" onSubmit={ envoyerReclamation }>

          <label>{t('t378')}</label>

          <label className='label-radio mt30'><input type="radio" name='reclamation' className="mr10" value={t('t374')} checked={reclamation == t('t374') ? true : false} onChange={ e => setReclamation(e.target.value) } required={true}/>{t('t374')}</label>
          <label className='label-radio'><input type="radio" name='reclamation' className="mr10" value={t('t375')} checked={reclamation == t('t375') ? true : false} onChange={ e => setReclamation(e.target.value) } />{t('t375')}</label>
          <label className='label-radio'><input type="radio" name='reclamation' className="mr10" value={t('t376')} checked={reclamation == t('t376') ? true : false} onChange={ e => setReclamation(e.target.value) } />{t('t376')}</label>
          <label className='label-radio'><input type="radio" name='reclamation' className="mr10 mb0" value={t('t377')} checked={reclamation == t('t377') ? true : false} onChange={ e => setReclamation(e.target.value) } />{t('t377')}</label>

          <label className='mt30'>{t('t379')}</label>
          <textarea value={reclamationText} onChange={ e => setReclamationText(e.target.value) } className='bg7'></textarea>

          <input type="submit" name="valider" value={t('envoyer')} className="btn bg2 cl1 hover-bg3 hover-cl1 mb30"/>

        </form>

      </div>

      <Footer/>
      
  </div>

  )
}

export default LivraisonRefuse