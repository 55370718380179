import React, { useEffect, useState } from 'react'
import { Footer, Header, HeaderBox } from '../../../components/imports'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { APP_NAME } from '../../../constants/general'
import Func from '../../../class/Func'
import { useTranslation } from 'react-i18next'

function AjusterLivraison() {

  const { t } = useTranslation()

  const navigate = useNavigate()

  const { id } = useParams()

  const [get_params] = useSearchParams()
  
  const [old_montant, setOld_montant] = useState(0)

  const ajuster = async (e, id) => {
    
    e.preventDefault()

    const results = await Func.fetch("annonces/commandes/ajusterLivraison", {id: id, montant: e.target.montant.value})

    if(results.success){
      
      Func.alert(t('t45'))
      
      navigate('/commandes/0')

    }else{

      Func.alert("ERROR.")

    }

}

  useEffect(() => {

    let montant = get_params.get("montant")

    setOld_montant(montant)

    document.title = t('t46') + " - " + APP_NAME

    window.scrollTo(0, 0)

} , [])

  return (
    <div>
      <Header/>

      <HeaderBox/>

      <div className='c flex ai-center jc-center mb30'>

        <form method="POST" className="form mt50 brca p50 col-5 m_mt_0" onSubmit={e => ajuster(e, id)}>

          <h1 className='ff fz18 mb30'>{ t('t47') }</h1>
  
          <label>{ t('t48') }</label>
          
          <textarea name='montant' placeholder={ t('t49') } required={true} defaultValue={old_montant}></textarea>

          <div className='flex jc-start ai-center'>

            <input type="submit" name="valider" value={t('modifier')} className="btn bg3 cl1 hover-bg3 hover-cl1 m0"/>

            <span className='btn bg2 cl1 ml10 m_d_block m_ml_0 m_mt_10' onClick={ e => navigate(-1) }>{ t('annuler') }</span>

          </div>

        </form>

      </div>

      <Footer/>
      
  </div>

  )
}

export default AjusterLivraison