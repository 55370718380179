import React, { useEffect, useState } from 'react'
import { Header, Footer, HeaderBox } from '../components/imports'
import { APP_NAME } from '../constants/general'
import User from '../class/User'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import Func from '../class/Func'
import { useContext } from 'react'
import { Context } from '../class/Context'
import wilaya from '../Json/wilaya.json'
import { useTranslation } from 'react-i18next'

function InscriprionClient() {

  const {t} = useTranslation()

  const [get_params] = useSearchParams()

  const [error, setError] = useState('');

  const navigate = useNavigate()

  const context = useContext(Context)

  const [red, setRed] = useState('/parametres?inscription=true')

  const Register = async (e, setError, navigate, setSession) => {

    e.preventDefault()
  
    let  inputs = e.target
    let  name = inputs.name
    let  email = inputs.email
    let  tele = inputs.tele
    let  wilaya = inputs.wilaya
    let  adresse = inputs.adresse
    let  password = inputs.password
    let  password_2 = inputs.password_2
  
    if(name.value !== "" && email.value !== "" && tele.value !== "" && password.value !=="" && password_2.value !=="" && wilaya.value !=="" && adresse.value !=="" ){
  
      if(password.value !== password_2.value){
  
        setError(t('t436'))
    
        window.scrollTo(0, 0)
  
        return false
  
      }
  
      const results = await User.registerClient(inputs)
  
      if(results.success){
  
        Func.saveSession(results.data, results.likes, setSession)
  
        navigate(red)
  
      }else{
  
        let t_msg = 'ERROR'
        if(results.message == 'err2') t_msg = t('t438')

        setError(t_msg)

      }
  
    }else{
  
      setError('Assurez-vous que tout les champs sont remplis')
  
      window.scrollTo(0, 0)
      
    }
  
  }

  useEffect(() => {

    document.title = t('t447') + " - " + APP_NAME

    window.scrollTo(0, 0)

    if(get_params.get("r") !== null) setRed(get_params.get("r"))

  }, []);

  return (
    <div>
       <Header/>
       <HeaderBox/>

       <div className='c flex ai-center jc-center'>

        <div className='pt50 pb50 cnx'>
            <h1 className='cl8 ff fz3'>{t('t419')}</h1>

            <p className='cl8 pt30 pb50'>{t('t448')}</p>

            <form action="" method="POST" className="form p20m" onSubmit={(e) => Register(e, setError, navigate, context.setSession)}>

                {( error  !== "") && <p className='error'>{error}</p>}
                
                <input type="hidden" name="token" required="true" value="54234234834"/>

                <label className='mb10'>{t('t382')}</label>
                <input type="text" name="name" placeholder={t('t382')} required={true} className='bg7'/>
                
                <label className='mt10 mb10'>{t('t213')}</label>
                <input type="email" name="email" placeholder={t('t213')}  required={true} className='bg7' />

                <label className='mt10 mb10'>{t('t214')}</label>
                <input type="tel" name="tele" placeholder={t('t214')}  required={true} className='bg7'  /> 

                <div className='grid2'>

                  <div>
                    <label className='mb10'>{t('t196')}</label>
                    <select name="wilaya" required={true} className='bg7'>

                      {

                        wilaya.map((getwilaya, index)  => (

                          <option value={getwilaya.id} key={index}>{getwilaya.name}</option>

                        ))
                      }

                    </select>
                  </div>

                  <div>
                    <label className='mb10'>{t('adresse')}</label>
                    <input type="text" name="adresse" placeholder={t('adresse')}  required={true} className='bg7'/>
                  </div>

                </div>

                <label className='mt10 mb10'>{t('t423')}</label>
                <input type="password" name="password" placeholder={t('t423')} required={true} className='bg7'/>

                <label className='mt10 mb10'>{t('t435')}</label>
                <input type="password" name="password_2" placeholder={t('t435')} required={true} className='bg7'/>

                <div className='flex jc-start ai-start mb10 ml20 m_flex'>
                  <input type="checkbox" name='checkbox_conditions'  required={true} className='checkbox col-1'/>
                  <div className='ml10 ta-start col-9'>{t('t442')} <Link to={'/page/conditions-generales-d-utilisation-de-la-marketplace-taqalid' } className="cl3 fw5 d-inline">{t('t443')}</Link></div>
                </div>

                <div className='flex jc-start ai-start ml20 m_flex'>
                  <input type="checkbox" name='checkbox_politique' required={true} className='checkbox col-1'/>
                  <div className='ml10 ta-start col-9'>{t('t444')} <Link to={'/page/politique-de-confidentialite'} className="cl3 fw5">{t('t445')}</Link></div>
                </div>
                <input type="submit" name="log" value={t('t446')} className="btn bg3 cl1 hover-bg5 hover-cl2 col-2 ml10"/>                     
            </form>
        </div>
       </div>
       <Footer/> 
    </div>
  )
}

export default InscriprionClient