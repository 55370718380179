import React, { useEffect, useState , useContext } from 'react'
import { Header, Footer, HeaderBox } from '../components/imports'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import User from '../class/User'
import { APP_NAME } from '../constants/general'
import Func from '../class/Func'
import { Context } from '../class/Context'
import { useTranslation } from 'react-i18next'

function Inscriprion() {

  const {t} = useTranslation()

  const [get_params] = useSearchParams()

  const [error, setError] = useState('')

  const [token, setToken] = useState('')

  const navigate = useNavigate()

  const context = useContext(Context)

  const Register = async (e, setError, navigate, setSession) => {

    e.preventDefault()
    let inputs = e.target
    let name = inputs.name
    let email = inputs.email
    let password = inputs.password
    let password_2 = inputs.password_2
  
    if(email.value !== "" && password.value !=="" && password_2.value !=="" && name.value !== ""){
  
      if(password.value !== password_2.value){
  
        setError(t('t436'))
    
        window.scrollTo(0, 0)
  
        return false
  
      }
  
      const results = await User.register_pro(inputs);
  
      if(results.success){
  
        Func.saveSession(results.data,results.likes, setSession)
  
        navigate('/modifierboutique?inscription=true')
  
      }else{
  
        let t_msg = t('t437')
        if(results.message == 'err2') t_msg = t('t438')

        setError(t_msg)
  
      }
  
    }else{
  
      setError('Assurez-vous que tout les champs sont remplis')
    }
  
  } 

  useEffect(() => {

    let p_token = get_params.get("token")

    if(p_token == null) navigate('/404')

    setToken(p_token)

    document.title = t('t439') + " - " + APP_NAME

    window.scrollTo(0, 0)

  }, []);

  return (
    <div>
       <Header/>
       <HeaderBox/>

       <div className='c flex ai-center jc-center'>

        <div className='pt50 pb50 cnx'>

            <h1 className='cl8 ff fz3'>{t('t419')}</h1>

            <p className='cl8 pt30 pb50'>{t('t440')}</p>

            <form method="POST" className="form p20m" onSubmit={(e) => Register(e, setError , navigate, context.setSession)}>

                {( error  !== "") && <p className='error cl1'>{error}</p>}

                <input type="hidden" name="token" required="true" value={ token }/>

                <label className='mt10 mb10'>{t('t441')}</label>
                <input type="text" name="name" placeholder={t('t441')} required="true" className='bg7' /> 

                <label className='mt10 mb10'>{t('t213')}</label>
                
                <input type="text" name="email" placeholder={t('t213')} required="true" className='bg7' />

                <label className='mt10 mb10'>{t('t214')}</label>
                <input type="text" name="tele" placeholder={t('t214')} required="true" className='bg7' /> 


                <label className='mt10 mb10'>{t('t423')}</label>

                <input type="password" name="password" placeholder={t('t423')} required="true" className='bg7'/>

                <label className='mt10 mb10'>{t('t435')}</label>
                
                <input type="password" name="password_2" placeholder={t('t435')} required="true" className='bg7'/>

                <div className='flex jc-start ai-start mb10 m_flex'>
                  <input type="checkbox" name='checkbox_conditions' className='checkbox' required={true} />
                  <div className='fz09 ml10 ta-start m_col_9'>{t('t442')} <Link to={'/page/conditions-generales-d-utilisation-de-la-marketplace-taqalid' } className="cl3 fw5">{t('t443')}</Link></div>
                </div>

                <div className='flex jc-start ai-start m_flex'>
                  <input type="checkbox" name='checkbox_politique' className='checkbox' required={true} />
                  <div className='fz09 ml10 ta-start'>{t('t444')} <Link to={'/page/politique-de-confidentialite'} className="cl3 fw5">{t('t445')}</Link></div>
                </div>

                <input type="submit" name="log" value={t('t446')} className="btn bg3 cl1 hover-bg5 hover-cl2 col-2 ml10"/>                     

            </form>

        </div>

       </div>

       <Footer/> 

    </div>
  )
}

export default Inscriprion