import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Footer, Header, HeaderBoutique, HeaderBox } from '../../components/imports'
import { Aside } from '../../components/Aside'
import { APP_NAME, WEBROOT } from '../../constants/general'
import { Context } from '../../class/Context'
import Func from '../../class/Func'
import Moment from 'react-moment'
import { useTranslation } from 'react-i18next'


function Factures() {

  const {t} = useTranslation()

  const navigate = useNavigate()

  const context = useContext(Context)

  const [factures, setFactures] = useState([])

  const getFactures = async () => {
    
    const results = await Func.fetch("users/factures", {})

    if(results.success){

      setFactures(results.data)

    }else{

      setFactures(t('t241'))

    }

  }

  useEffect(() => {

    Func.session(context, navigate)

    document.title = t('t240') + " - " + APP_NAME

    getFactures()

  }, []);

  return (

    <div>
      <Header/>

      <HeaderBox/>

      <HeaderBoutique/>

      <div className='c flex mb30'>
        
        <Aside/>
        
        <div className='col-10 p20m'>
          
          <h1 className='ff fz18 mb20 mt80 m_mt_0'>{t('t240')}</h1>

          {

            factures.length > 0

            ?

              typeof factures == 'string'

              ?

              <p>{ factures }</p>

              :

              <div className='fz07'>
                  <div className='grid4 bg5 fw6 m_grid4'>

                    <p className='p10'>{t('t242')}</p>
                    <p className='p10'>{t('t243')}</p>
                    <p className='p10'>{t('t244')}</p>
                    <p className='p10'></p>

                  </div>

                {
                  
                  factures.map(a =>

                    <div className='grid4 m_grid4'>

                      <p className='p10'>{ a.month }</p>
                      <p className='p10'>{ a.total_all } DA</p>
                      <p className='p10'><Moment format='DD/MM/YYYY' locale="fr">{ a.created_at }</Moment></p>
                      <p className='p10'><a href={WEBROOT + 'facture/index.php?boutique_id='+ a.boutique_id +'&month=' + a.month} target='_blank' className='btn bg3 cl1 fz08'>{t('t42')}</a></p>

                    </div>

                  )

                }

              </div>

            :

            <div className='flex jc-center mt50 mb50'><div className="lds-dual-ring col"></div></div>

          }

        </div>
      </div>

      <Footer/>

  </div>

  )
}

export default Factures