import React, { useEffect, useState } from 'react'
import { Header, Footer, HeaderBox } from '../components/imports'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { APP_NAME } from '../constants/general'
import User from '../class/User'
import { useContext } from 'react'
import { Context } from '../class/Context'
import Func from '../class/Func'
import { useTranslation } from 'react-i18next'

function Connexion() {

  const {t} = useTranslation()

  const [get_params] = useSearchParams()

  const navigate = useNavigate()

  const [links, setLinks] = useState(false)

  const [red, setRed] = useState('/')

  const [error, setError] = useState('')

  const context = useContext(Context)
  
  const getLinks = async () => {

    const results = await Func.fetch("users/connect/links", false)

    setLinks(results)

  }

  const Connect = async (e, setError, navigate, setSession) => {

    e.preventDefault()
  
    let inputs = e.target
    
    let email = inputs.email
  
    let pass = inputs.pass
  
    if( email.value !=="" &&  pass.value !==""){
  
      const results  = await User.login(inputs)
  
      if(results.success){
         
          Func.saveSession(results.data,results.likes, setSession)
  
          navigate(red)

      }else{
  
        let t_msg = t('t428')
        if(results.message == 'm2') t_msg = t('t429')
        setError(t_msg)
      }
  
    }else{
      setError(t('t418'))
    }
      
  }

  useEffect(() => {

    document.title = t('t417') + " - " + APP_NAME
    
    window.scrollTo(0, 0)

    getLinks()

    if(get_params.get("r") !== null) setRed('/' + get_params.get("r"))

  }, [])

  return (
    <div>

      <Header/>

      <HeaderBox/>
      
      <div className='c flex ai-center jc-center cnx'>

        <div className='pt100 pb100 col-6 m_p10'>

            <h1 className='cl8 ff fz3'>{t('t419')}</h1>

            {(error !== '') && <p className="error">{error}</p>}

            {

              links

              ?

                  <div className='grid2 mt20 mb20'>
                      <Link to={ links.facebook_url } className="btn bg3 cl1 flex ai-center jc-center m_flex"><svg className="mr10" width="30" height="30" viewBox="0 0 192 192" xmlns="http://www.w3.org/2000/svg" fill="none"><path stroke="#fff" stroke-linecap="round" stroke-width="12" d="M96 170c40.869 0 74-33.131 74-74 0-40.87-33.131-74-74-74-40.87 0-74 33.13-74 74 0 40.869 33.13 74 74 74Zm0 0v-62m30-48h-10c-11.046 0-20 8.954-20 20v28m0 0H74m22 0h22"/></svg> {t('t420')}</Link>
                      <Link to={ links.google_url } className="btn bg3 cl1 flex ai-center jc-center m_flex"><svg className="mr10" width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.5475 8.30327C14.6407 7.49361 13.4329 7 12.1089 7C9.28696 7 7 9.23899 7 12C7 14.761 9.28696 17 12.1089 17C15.5781 17 16.86 14.4296 17 12.4167H12.841" stroke="#fff" stroke-width="1.5"/><path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg> {t('t421')}</Link>
                  </div>
              :

                  <div className='flex jc-center mt50 mb50'>
                      <div className="lds-dual-ring col"></div>
                  </div>

            }

            <p className='cl8 pt30 pb30'>{t('t422')}</p>

            <form method="POST" className="form" onSubmit={(e) => Connect(e,setError,navigate, context.setSession)}>

              <label className='mt10 mb10'>{t('t213')}</label>

              <input type="text" name="email" placeholder={t('t213')} required="true" className='bg7'/>

              <label className='mt10 mb10'>{t('t423')}</label>

              <input type="password" name="pass" placeholder={t('t423')} required="true" className='bg7'/>
              
              <input type="submit" name="log" value={t('t424')} className="btn bg3 cl1 hover-bg2 col-3 mb30"/>
        
              <Link to={'/recuperer-mot-de-passe'} className='cl3 mt50 hover-cl5 label'>{t('t425')}</Link>
            
              <div className='mt20'>{t('t426')} <Link to={`/inscriptionClient${red == '/' ? '' : '?r=' + red}`} className='cl3  hover-cl2'>{t('t427')}</Link> </div>     
              
            </form>

        </div>

      </div>

      <Footer/> 
  
    </div>
  )
}

export default Connexion