import { API_ROOT, API_SECRET, AVATAR_DEFAULT, AVATAR_ROOT, COVER_DEFAULT, COVER_ROOT } from "../constants/general";

class Func {

    static session(context, navigate) {

        if((context.session !== true)) navigate('/connexion') 

    }

    static saveSession(data, likes, setSession) {
             
        localStorage.setItem('idU', data.idU);
        localStorage.setItem('app_token', data.app_token);
        localStorage.setItem('name', data.name);
        localStorage.setItem('email', data.email);
        localStorage.setItem('user_type', data.user_type);
        localStorage.setItem('avatar', data.avatar);
        localStorage.setItem('cover', data.cover);
        localStorage.setItem('wilaya', data.wilaya);
        localStorage.setItem('likes', likes);

        if(localStorage.getItem('panier') == null) localStorage.setItem('panier', '[]');

        setSession(true)

    }

    static logout = (context, navigate) => {

        context.setSession(false)

        localStorage.removeItem('idU');
        localStorage.removeItem('app_token');
        localStorage.removeItem('name');
        localStorage.removeItem('email');
        localStorage.removeItem('user_type');
        localStorage.removeItem('avatar');
        localStorage.removeItem('cover');
        localStorage.removeItem('likes');
        // localStorage.removeItem('wilaya');
        // localStorage.removeItem('panier');

        navigate('/')   

    }

    static openMenu(){

        document.body.classList.toggle('mn')
    
    }

    static avatar(avatar){

        if(avatar == 0){

            return AVATAR_DEFAULT


        }else{

            return AVATAR_ROOT + avatar + '.jpg'
            
        }
    
    }

    static cover(cover){

        if(cover == 0){

            return COVER_DEFAULT


        }else{

            return COVER_ROOT + cover + '.jpg'
            
        }
    
    }
    
    static note(note){

        return(

            <div className="fz11 cl3 hover-cl3 pb5">
                { note >= 1 ? <span className='icon'>i</span> : <span className='icon'>g</span> }
                { note >= 2 ? <span className='icon'>i</span> : <span className='icon'>g</span> }
                { note >= 3 ? <span className='icon'>i</span> : <span className='icon'>g</span> }
                { note >= 4 ? <span className='icon'>i</span> : <span className='icon'>g</span> }
                { note >= 5 ? <span className='icon'>i</span> : <span className='icon'>g</span> }
            </div>
        )
    
    }

    static alert(message){

        let mask = document.createElement('div')
        mask.classList.add('mask')
    
        let gla_alert = document.createElement('div')
        gla_alert.classList.add('gla_alert')
    
        gla_alert.innerHTML = `
           
            <p>${message}</p>
    
            <div class="gla_btns">
                <span class="cl1 hover-cl3" onclick="document.querySelector('.mask').remove()">OK</span>
            </div>
       
        `
        mask.appendChild(gla_alert)
    
        document.querySelector('body').appendChild(mask)
    
    }

    static envoyeMessageBox(message){

        let messages_box = document.querySelector('#messages_box')

        let mon_message = document.createElement('div')
        mon_message.classList.add('message', 'bg1', 'p10', 'br8', 'col-8')
        mon_message.style.marginLeft = '20%'

        let cnt = document.createElement('div')
        cnt.classList.add('cnt')

        let p = document.createElement('div')
        p.innerText = message

        cnt.append(p)
        mon_message.append(cnt)

        messages_box.append(mon_message)

    }

    static fetch(url, vars, auth = true){

        let obj = {}

        if(auth){

            obj = {
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token')
            }

        }else{

            obj = {
                api_secret: API_SECRET
            }

        }

        let body = {...obj, ...vars}
        
        let data = fetch(API_ROOT + url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body)
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

}

export default Func