import React, { useEffect , useState} from 'react'
import { APP_NAME, WEBROOT } from '../../constants/general'
import { Header, HeaderBox , Footer, HeaderBoutiquePublic } from '../../components/imports'
import {  useNavigate, Link, useParams, Navigate } from 'react-router-dom'
import User from '../../class/User'
import { ArticleCategorie } from '../../components/Articles'
import wilaya from '../../Json/wilaya.json'
import Moment from 'react-moment'
import Func from '../../class/Func'
import { useTranslation } from 'react-i18next'

function Boutique() {

  const {t} = useTranslation()

  const { id } = useParams()

  const navigate = useNavigate()

  const [infos, setInfos] = useState(false)

  const [tarifs, setTarifs] = useState("")

  const [annonces, setAnnonces] = useState(false)

  const [loading, setLoading] = useState(false)

  const [politic, setPolitic] = useState(false)

  const [ta, setTa] = useState(false)

  const [avis, setAvis] = useState("")

  const [avisLimit, setAvisLimit] = useState(1)

  const [avisLoad, setAvisLoad] = useState(true)

  const toggleShowPolitique = () =>{

    setPolitic(!politic)
  }

  const toggleShow = () =>{

    setTa(!ta)

  }

  const myInfos = async () => {

    let results = await User.profile(id)

    if(results.success){
  
      setInfos(results.data)

      setAnnonces(results.annonces)

      boutiqueAvis(1)

      getTarifsGlobal(results.data.idU)

    }else{
      navigate("/404")
    }
  
  } 

  const boutiqueAvis = async (limit) => {

    setAvisLoad(true)

    let results = await Func.fetch("users/boutiqueAvis", {"id" : id, "limit" : limit})

    if(results.success){
  
      setAvis(results.avis)

    }

    setAvisLoad(false)
  
  } 

  const moreAvis = () => {

    setAvisLimit(avisLimit + 1)
    
    boutiqueAvis(avisLimit + 1)

  }

  const getTarifsGlobal = async(id) =>{

    setLoading(true)

    const results = await User.getTarifGlobal(id)
    
    setTarifs(results.data)

    setLoading(false)

  }

  useEffect(() => {

    document.title = t('t192') + " - " + APP_NAME
    
    window.scrollTo(0, 0)

    myInfos()
   
  }, []);

  return (
    <div>

        <Header/>

        <HeaderBox/>

        <HeaderBoutiquePublic data={infos}/>

        {
          infos

        ?
          <div className='c pos-r'>

            <div className='mb10 mt40 m_p10'>

              <div className='mt80 mb40 col-10 m_mt_70 details'>

                <h2 className='mb10'>{t('t21')}</h2>

                <p className='mb10 lh p'>{infos.description}</p>

              </div>

              <h2 className='mb20'>{t('t193')}</h2>
        
              {

                annonces

                ?

                  typeof annonces == 'string'

                  ?

                    <p>{t('t194')}</p>

                  :

                    <div className="grid5 cards articles mb30 m_p0">
                      {annonces.map(element => <ArticleCategorie data={element} key={element.idA} />)}
                    </div>

                :

                  <div className='flex jc-center mt50 mb50'>
                      <div className="lds-dual-ring col"></div>
                  </div>

              }

            </div>

            <div className='mt50 mb30 m_p10 m_mt_0'>

                <h2 className='mb10'>{t('t195')}</h2>

                <div className='grid3 grid3m fw6'>
                  <p className='bg7 br8 p10'>{t('t196')}</p>
                  <p className='bg7 br8 p10'>{t('t197')}</p>
                  <p className='bg7 br8 p10'>{t('t198')}</p>
                </div>

                {

                  !loading

                ?
                
                  <>

                    {tarifs.map((t)=>

                      t.dom == '/' && t.relais == '/'

                      ?

                      <></>

                      :

                      <div key={t.idL} className='grid3 grid3m mb10'>

                        <p className='p10 brcb'>{wilaya[t.wilaya - 1 ].name}</p>

                        <p className='p10 brcb'>{t.dom !== '/' ? t.dom + ' DA' : t.dom}</p>

                        <p className='p10 brcb'>{t.relais !== '/' ? t.relais + ' DA' : t.relais}</p>
                      
                      </div>
                    
                    )}
            
                  </>

                :
                  <div className='flex jc-center mt40 mb40'>
                    <div className="lds-dual-ring col"></div>
                  </div>
                }

           
            
                <h2 className='mt40'>{t('t199')}</h2>

                <p className='mb30'>{ infos.autre_partenaire !== "" ? infos.autre_partenaire : infos.livraison }</p>

                <h2 className='mt40'>{t('t200')}</h2>

                <p className='mb30'>{t(infos.delaislivraison)}</p>

                <div className='mt40 mb80 m_mb_0 details'>

                  <h2 className='mb10'>{t('t201')}</h2>

                  {

                    infos.politique == "1"

                  ?

                    <p className='mb10 lh p'>{infos.politiquetext.slice(0, 270)}

                    {

                      politic 
                      
                    && 

                      (infos.politiquetext.length > 270 && (infos.politiquetext.slice(270)))
                      
                    } 

                    {

                      infos.politiquetext.length <= 270

                    ?

                      <p className='dnone'></p>

                    :

                      <p onClick={toggleShowPolitique} className='cl3 mb30 cp'>{politic ? t('t203') : t('t202')} <span className='icon'>2</span></p>

                    }

                    </p>

                  :

                    <div className='fz11 mb20'>
                      
                      <p> •	{t('t204')}</p>
                      <p> •	{t('t205')}</p>
                      <p> •	{t('t206')}</p>
                      <p> •	{t('t207')}</p>
                      <p> •	{t('t207_2')}</p>

                    </div> 

                  }

                </div>

                <h2 className='mt40'>{t('t208')}</h2>

                <div className='flex jc-start m_flex m_p10'>{ Func.note(infos.note) } <span className='ml10'>({ infos.nbr_note } {t('t31')})</span></div>

                {

                  typeof avis == 'object'

                  ?

                  <>

                    {

                    avis.map(a =>

                      <div className='avis brca mt20 col-10'>

                        <div className='flex'>

                          <div className='col-5 flex ai-center flex-start pos-r'>
                            <img src={ Func.avatar(a.avatar) } className=" avatar-small" />
                            <p className='ml10 cl8 fz11 M fw6 name'>{ a.name }</p>
                          </div>

                          <p className='cl8 mt30 fz09 M fw5'><Moment format='DD/MM/YYYY à HH:II' locale="fr">{ a.date }</Moment></p>

                        </div>


                        { Func.note(a.note) }

                        <p className='mt10'>{ a.text }</p>

                        {

                          a.reponse !== null

                          &&

                          <p className='mt20 fz09'><span className='fw6'>{t('t209')}</span> : { a.reponse }</p>

                        }
                      
                      </div>

                    )

                    }

                    {

                      avisLoad
                      
                      &&

                      <div className='flex jc-center mt40 mb40'>
                        <div className="lds-dual-ring col"></div>
                      </div>

                    }

                    {

                      avis.length % 20 == 0

                      &&

                      <div className='mt40'>
                        <span className='btn bg3 cl1' onClick={ moreAvis }>{t('t210')}</span>
                      </div>

                    }

                  </>

                  :
                  
                  <p className='mt20'>{ avis }</p>

                } 

              </div>

          </div>
        :

          <div className='flex jc-center mt40 mb40'>
            <div className="lds-dual-ring col"></div>
          </div>
        }

        <Footer/>

    </div>
  )
}

export default Boutique