import React, { useEffect } from 'react'
import { Footer, Header, HeaderBox } from '../../../components/imports'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { APP_NAME } from '../../../constants/general'
import Func from '../../../class/Func'
import { useTranslation } from 'react-i18next'

function RetourCommande() {

  const {t} = useTranslation()

  const { id } = useParams()

  const navigate = useNavigate()

  const retourner = async (e, id) => {
    
    e.preventDefault()

    const results = await Func.fetch("annonces/commandes/retournee", {id: id, raison: e.target.raison.value})

    if(results.success){

      Func.alert(t('t72'))

      navigate('/commandes/6')

    }else{

      Func.alert("ERROR.")

    }

}

  useEffect(() => {

    document.title = t('t73') + " - " + APP_NAME

    window.scrollTo(0, 0)

} , [])

  return (
    <div>
      <Header/>

      <HeaderBox/>

      <div className='c flex ai-center jc-center mb30'>

        <form method="POST" className="form mt50 brca p50 m_mt_0" onSubmit={e => retourner(e, id)}>

          <h1 className='ff fz18 mb30'>{t('t74')}</h1>
  
          <label>{t('t75')}</label>
          
          <label className='label-radio mt30'><input type="radio" name='raison' value="Client injoignable" className="mr10" required={true} />{t('t76')}</label>
          <label className='label-radio'><input type="radio" name='raison' value={t('t77')} className="mr10" />{t('t77')}</label>

          <p className='fz09 mb30'>{t('t78')}</p>

          <input type="submit" name="valider" value={t('envoyer')} className="btn bg2 cl1 hover-bg3 hover-cl1 mb30"/>

        </form>

      </div>

      <Footer/>
      
  </div>

  )
}

export default RetourCommande