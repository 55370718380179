const WEBROOT = "https://taqalid.dz/"

const ROOT = "https://taqalid.dz/"

const API_ROOT = "https://taqalid.dz/api/"

const AVATAR_ROOT = "https://taqalid.dz/image/profile/"

const COVER_ROOT = "https://taqalid.dz/image/cover/"

const PHOTOS_ROOT = "https://taqalid.dz/image/annonces/"

const VIDEOS_ROOT = "https://taqalid.dz/image/video/"

const APP_NAME ="Taqalid"

const AVATAR_DEFAULT = "/image/avatar.svg"

const COVER_DEFAULT = "/image/cover.jpg"

const API_SECRET = "123123"

export { WEBROOT, ROOT, API_ROOT, APP_NAME ,API_SECRET, AVATAR_DEFAULT, AVATAR_ROOT, PHOTOS_ROOT,VIDEOS_ROOT, COVER_ROOT, COVER_DEFAULT}