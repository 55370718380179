import React, { useEffect, useState } from 'react'
import { Header, HeaderBox , Footer, HeaderBoutique, ToolbarCommande } from '../../../components/imports'
import { Aside } from '../../../components/Aside'
import Annonces from '../../../class/Annonces'
import { APP_NAME, PHOTOS_ROOT } from '../../../constants/general';
import Moment from 'react-moment';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ArtcilePanierBoutique } from '../../../components/Articles';
import Func from '../../../class/Func';

function Commandes() {

  const url = useParams()

  const [loading, setLoading] = useState(true)

  const [achats, setAchats] = useState([])

  const [pastille, setPastille] = useState(false)

  const getVentes = async (stt) => {

    setAchats([])

    setLoading(true)
    
    const results = await Annonces.ventes(JSON.stringify({stt:stt, limit: '30'}))
    
    setLoading(false)

    setAchats(results.data)

    setPastille(results.pastille)

  }

  useEffect(() => {

      document.title = "Mes commandes - " + APP_NAME

      getVentes(url.url)
  
  }, [url])

  return (

    <div>

        <Header/>

        <HeaderBox/>

        <HeaderBoutique/>

        <div className='c mb30'>

          <div className='flex'>

            <Aside/>

            <div className='col-78 m_p10'>

              <ToolbarCommande url={url.url} pastille={pastille}/>

              <div className='mt30'>

                { loading && <div className='flex jc-center mt50 mb50'><div className="lds-dual-ring col"></div></div> }

                { achats && achats.map(achat => <ArtcilePanierBoutique achat={achat} key={achat.idP}/> ) }

              </div>

            </div>

          </div>

        </div>

        <Footer/>

    </div>

  )

}

export default Commandes

