import React from 'react'
import { Footer, Header, HeaderBox } from '../../components/imports'
import { useEffect } from 'react'
import Func from '../../class/Func'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useContext } from 'react'
import { Context } from '../../class/Context'
import { APP_NAME, AVATAR_ROOT } from '../../constants/general'
import User from '../../class/User'
import { useState } from 'react'
import { AsideClient } from '../../components/Aside'
import wilaya from '../../Json/wilaya.json'
import { useTranslation } from 'react-i18next'

function Parametres() {

  const {t} = useTranslation()

  const navigate = useNavigate();

  const context = useContext(Context)

  const [get_params] = useSearchParams()

  const [editPassword, setEditPassword] = useState(false)

  const [infos, setInfos] = useState(false)

  const [loading, setLoading] = useState(false)
  
  const [flash, setFlash] = useState('')

  const [city, setCity] = useState('')

  const uploadAvatar = async (e) => {

    let file = e.target.files[0]
  
    if(file.size == 0 || file.name == null) setFlash({ type: 'error', message: "Impossible d'ajouter cette photo une erreur est servenu" })
  
    setLoading(true)
  
    let results = await User.photoUpload(file)
  
    setLoading(false)
  
    if (results.success && results.avatar !== undefined){
  
        localStorage.setItem('avatar', results.avatar)
  
        document.querySelectorAll('.avatar_img').forEach(elem => {
  
            elem.src = AVATAR_ROOT + results.avatar + '.jpg?t=' + Date.now()
  
        })
  
        setFlash('')
  
    }else{
  
        setFlash({type: 'error', message: results.message})
        
    }
  
  }

  const modifier = async (e) =>{

    e.preventDefault()

    setLoading(true)

    let inputs = e.target

    if(localStorage.getItem('wilaya') !== inputs.wilaya.value) localStorage.setItem('panier', '[]')

    const results = await User.modifierInformations(inputs)

    setLoading(false)

    if(results.success){

        setFlash({type: 'succes', message: t('t380')})
        
        localStorage.setItem('wilaya',inputs.wilaya.value)

    }else{

        setFlash({type: 'error', message: results.message})

    }

    window.scrollTo(0, 0)

  }

  const modifierMotdepasse = async (e) =>{

    e.preventDefault()

    setLoading(true)

    let inputs = e.target

    const results = await User.modifierMotdepasse(inputs)

    setLoading(false)

    if(results.success){

        e.target.password_old.value = ''
        e.target.password.value = ''
        e.target.password_r.value = ''

        setFlash({type: 'succes', message: t('t251')})

    }else{

        setFlash({type: 'error', message: t('t252')})

    } 

  }

  const myInfos = async () => {

    let results = await User.session(true)

    if(results.success){

      setInfos(results.data)

      setCity(results.data.wilaya)

    }

  }

  useEffect(() => {

    Func.session(context, navigate)

    myInfos()

    if(get_params.get("inscription") !== null) setFlash({type: "succes", message: t('t381')})

    document.title = t('t15') + " - " + APP_NAME

    window.scrollTo(0, 0)

  }, [])

  return (

    <div>
      
      <Header/>

      <HeaderBox/>

      <div className='c flex mt80 mb30 m_mt_20'>
        
       <AsideClient/>

        <div className='col-10'>

          <h1 className='ff fz18 mb30 ta-center'>{t('t15')}</h1>

          {(flash !== '') && <p className={ flash.type }>{ flash.message }</p>}

          <div className='grid2'>

            <form method="POST" className="form brca p30 m20m" onSubmit={(e) => modifier(e)} id='parametres'>

            <div class="mb20">

              <p className='M fw5 fz15 mb20'>{t('t217')}</p>

              <img src={ Func.avatar(localStorage.getItem('avatar')) } className="avatar_img"/>

              {

                loading

                ?
                
                <div className="lds-dual-ring col"></div>

                :

                <div className='mt20'>

                  <input type="file" name="picBoutique" id="file-6" class="inputfile" onChange={e => uploadAvatar(e)} style={{ display: 'none'}}/>
                
                  <label for="file-6"><span className='icon cl2 fz2 mr10'>W</span><span className='cl2 fz11'>{t('t217')}</span></label>
                </div>

              }


              </div>

              <label>{t('t382')}</label>
              <input type="text" name="name" placeholder={t('t382')} required="true"  className='bg7'  defaultValue={infos.name}/>
              
              <label className='mt10 mb10'>{t('t213')}</label>
              <input type="email" name="email" placeholder={t('t213')} required="true" className='bg7'  defaultValue={infos.email} disabled={true}/>

              <label className='mt10 mb10'>{t('t214')}</label>
              <input type="tel" name="tele" placeholder={t('t214')} required="true"className='bg7'  defaultValue={infos.tele} /> 

              
              <label className='mt30 mb10'>{t('t196')}</label>
      
              <select type="text" name="wilaya" required="true" className='bg7 wilayaId' onChange={(e) => setCity(e.target.value)} value={city}>
                      
                <option value={0}>{t('t274')}</option>
                {
            
                  wilaya.map((getwilaya, index)  => (

                  <option value={getwilaya.id} key={index}>{getwilaya.name}</option>

                  ))

                }
          
              </select>

              {

                localStorage.getItem('panier') !== '[]'

                &&
                
                <p className='fz09'>{t('t383')}</p>
              
              }

              <label className='mt30 mb10 '>{t('t384')}</label>

              <textarea name="adresse" className='bg7' defaultValue={infos.adresse} /> 


              <input type="submit" name="modifier" value={t('modifier')} className="btn bg3 cl1 hover-bg2"/>                     
            </form>

            <form method="POST" className="form brca p30 m20m" onSubmit={(e) => modifierMotdepasse(e)} id='parametres'>
                <p className='M fw5 fz15 mb20'>{t('t385')}</p>

                <label>{t('t253')}</label>
                <input type="password" name="password_old" placeholder={t('t253')} required="true" className='bg7'/>
                
                <label>{t('t254')}</label>
                <input type="password" name="password" placeholder={t('t254')} required="true" className='bg7'/>
                
                <label>{t('t255')}</label>
                <input type="password" name="password_r" placeholder={t('t255')} required="true" className='bg7'/>

                <input type="submit" name="modifier" value={t('modifier')} className="btn bg3 cl1 hover-bg2"  onClick={ e => setEditPassword(!editPassword)}/>                     
            </form>

          </div>

        </div>
      </div>

      <Footer/>

    </div>
  )
}

export default Parametres