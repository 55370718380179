import React, { useEffect, useState } from 'react'
import { Footer, Header, HeaderBox } from '../../components/imports'
import Func from '../../class/Func';
import { useNavigate, useParams } from 'react-router-dom';
import { API_ROOT, APP_NAME } from '../../constants/general';
import { useTranslation } from 'react-i18next';

function Litige() {

  const {t} = useTranslation()

  const id = useParams()

  const navigate = useNavigate()

  const [litige, setLitige] = useState('')
  const [litigeText, setLitigeText] = useState('')

  const [loading, setLoading] = useState(false)

  const [imageAdded, setImageAdded] = useState(0)

  const envoyerLitige = async (e) => {

    e.preventDefault()

    let file = e.target.uploadPhotosInput.files[0]
    
    if(file == undefined || file.size == 0 || file.name == null){

      Func.alert(t('t362'))

      return false

    }
  
    setLoading(true)
  
    let formdata = new FormData()
    formdata.append('image', file)
    formdata.append('id_user', localStorage.getItem('idU'))
    formdata.append('app_token', localStorage.getItem('app_token'))
    formdata.append('id', id.id)
    formdata.append('litige', litige)
    formdata.append('litigeText', litigeText)
        
    fetch(API_ROOT + "annonces/commandes/ajouterLitige", {
        method: "POST",
        body: formdata
    }).then(response => response.json())
    .then(results => {

      if(results.success){

        Func.alert(t('t363'))
  
        navigate('/commandes/5')
  
      }else{
  
        let t_msg = t('t364')

        if(results.message == "err2") t_msg = t('t365')

        Func.alert(t_msg)

        setLoading(false)
  
      }

    })

  }

  useEffect(() => {

    document.title = t('t361') + " - " + APP_NAME

    window.scrollTo(0, 0)

  }, [])

  return (
    <div>

      <Header/>

      <HeaderBox/>

      <div className='c flex ai-center jc-center mb30'>

        <form method="POST" className="form mt50 brca p20 m20m" onSubmit={ envoyerLitige }>
          
          <h1 className='ff fz18 mb30'>{t('t361')}</h1>
  
          <label>{t('t366')}</label>
          <textarea className='bg7' required={true} defaultValue={litige} onChange={e => setLitige(e.target.value)}></textarea>

          <label>{t('t367')}</label>
          <textarea name='details' className='bg7' required={true} defaultValue={litigeText} onChange={e => setLitigeText(e.target.value)}></textarea>

          <label className='mt10 mb10'>{t('t368')}</label>     

          {

            imageAdded == 1 && <p className='cl4 mb30'>{t('t369')}</p>

          }

          <div className='mb20'>
            <input type="file" name="uploadPhotosInput" id="uploadPhotosInput" className="inputfile" data-multiple-caption="{count} files selected" accept='image/*'  maxLength='1' style={{display: 'none'}} onChange={ e => setImageAdded(1) }/>
            <label for="uploadPhotosInput"><span className='icon cl2 fz2 mr10'>W</span><span className='cl2 fz12'>{t('t370')}</span></label>    
          </div> 

          {

            loading

            ?

            <div className='flex jc-center mt50 mb50'>
              <div className="lds-dual-ring col"></div>
            </div>

            :

            <input type="submit" name="valider" value={t('t371')} className="btn bg2 cl1 hover-bg3 hover-cl1 mb30"/>

          }
    
        </form>

            
      </div>
      <Footer/>
  </div>

  )
}

export default Litige