import React from 'react'
import { WEBROOT } from '../../constants/general'
import { Header, HeaderBox , Footer } from '../../components/imports'
import { Link } from 'react-router-dom'

function Politique() {
  return (
    <div>
        <Header/>
        <HeaderBox/>
        <div className='c mt50 p20m mb50'>
            <div className='ai-center'>
    
              <h1 className='ff fz2 cl3 ta-center'>Politique de confidentialité de Taqalid</h1>
              <div className='mt50 pos-r'>
                <h1 className='cl3 mb20 fz13 des'>A propos<span className='icon fz11 cl3 fw7 hover-cl3 up flex-end'>2</span></h1>
                <p  className='cl2 mb20 '>La politique de confidentialité fournit des informations sur la manière dont Taqalid collecte et 
                traite vos données personnelles lorsque vous visitez notre site Web. Elle expose ce que nous 
                faisons de vos données personnelles et comment nous les gardons en sécurité et explique les 
                droits que vous avez en ce qui concerne vos données personnelles.</p>
                    
              </div>

              <div className='mt50 pos-r'>
                <h1 className='cl3 mb20 fz13 des'>Qui sommes-nous ?<span className='icon fz11 cl3 fw7 hover-cl3 up flex-end'>2</span></h1>
                <p  className='cl2 mb20 '>Taqalid est une plateforme de commerce électronique spécialisée dans l’art et l’artisanat 
                  algérien. Notre place de marché (marketplace) met en relation les vendeurs et les 
                  consommateurs.</p>
                    
              </div>

              <div className='mt50 pos-r'>
                <h1 className='cl3 mb20 fz13 des'>Les données que nous recueillons à votre sujet ?<span className='icon fz11 cl3 fw7 hover-cl3 up flex-end'>2</span></h1>
                <p  className='cl2 mb20 '>Par données personnelles, on entend toute information qui peut être utilisée pour identifier 
                directement ou indirectement un individu spécifique. Nous collectons vos données personnelles 
                afin de fournir des produits et services sur mesure et afin d'analyser et d'améliorer 
                continuellement nos produits et services. Nous pouvons collecter, utiliser, stocker et transférer 
                différents types de données personnelles à des fins de marketing et d'optimisation des données 
                personnelles. Taqalid utilise également Google Digital Marketing pour proposer des offres ciblées 
                pour certains produits et services à nos clients.
                Vous nous fournissez vos données personnelles lorsque vous enregistrez vos données 
                personnelles sur notre site web et que vous effectuez des transactions avec ces derniers.
                Les données personnelles que nous collectons comprennent :
              
                </p>
                <ul>
                  <li>Informations que vous nous fournissez : Nous recevons et stockons les informations que 
                  vous nous fournissez, notamment vos données d'identité, vos données de contact, vos 
                  données biométriques et votre adresse de livraison. Ces types de données personnelles 
                  peuvent inclure :
                      <ul><li>
                      les coordonnées (telles que votre nom, vos adresses postales, vos numéros de téléphone 
                        et vos adresses électroniques),  </li>
                      </ul>
                  </li>

                </ul>                
              </div>

         
            </div>
           
        </div>
        <Footer/>
    </div>
  )
}

export default Politique