import React, { useContext, useEffect, useState } from 'react'
import { APP_NAME, WEBROOT } from '../../constants/general'
import { Header, HeaderBox , Footer, HeaderBoutique } from '../../components/imports'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Aside } from '../../components/Aside'
import Annonces from '../../class/Annonces'
import { ArticleParametres } from '../../components/Articles'
import Func from '../../class/Func'
import { Context } from '../../class/Context'
import { useTranslation } from 'react-i18next'

function MesArticles() {

  const {t} = useTranslation()

  const navigate = useNavigate();

  const context = useContext(Context)

  const [articles, setArticles] = useState()

  const getArticles = async () => {

    let results = await Annonces.mesAnnonces()

    setArticles(results)
    
  }

  useEffect(() => {

    Func.session(context, navigate)

    getArticles()

    document.title = t('t9') + " - " + APP_NAME

    window.scrollTo(0, 0)
    
  }, [])

  return (

    <div>
      
      <Header/>

      <HeaderBox/>

      <HeaderBoutique/>

      <div className='c'>

        <div className='flex'>

          <Aside/>

          <div className='col-78 mb30'>
    
            <div className='mt50 flex-end m_p10'><Link to={"/ajouter-article"}  className='cl1 btn bg2 col-3 ta-center  hover-bg3'>{t('t245')}</Link></div> 

            <div className='flex flex-start mb30 mt20'>

              <h1 className='ff fz18 mb20 mt80 m_mt_0'>{t('t9')}</h1>

            </div>

            {

              articles

            ?

              articles.data

            &&

              <div className="grid4 articles mb30">
                { articles.data.map(element => <ArticleParametres data={element} key={element.idA} />)}
              </div>

            :

              <div className='flex jc-center mt50 mb50'>
                <div className="lds-dual-ring col"></div>
              </div>

            }
           
          </div>

        </div>

      </div>

      <Footer/>
      
    </div>
  )
}

export default MesArticles

