import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyA_hVNEggxlCg6D6UQELiJz_Z-wUPlkXu8",
    authDomain: "taqalid-webapp.firebaseapp.com",
    projectId: "taqalid-webapp",
    storageBucket: "taqalid-webapp.appspot.com",
    messagingSenderId: "1088952992468",
    appId: "1:1088952992468:web:6471c8d007740ed9e3f141"
};

const app = initializeApp(firebaseConfig)

const firebase_db = getFirestore(app)

export { firebase_db }