import React, { useEffect } from 'react'
import { WEBROOT } from '../constants/general'
import { Header, HeaderBox , Footer } from '../components/imports'

import { motion, useAnimation } from "framer-motion";

import { useInView } from "react-intersection-observer";

function Apropos() {

  const control = useAnimation()
  const [ref, inView] = useInView()

  const variantR = {visible: { opacity: 1, x: 0 },hidden: { opacity: 0, x: 200 }}
  const variantL = {visible: { opacity: 1, x: 0 },hidden: { opacity: 0, x: -200 }}

  useEffect(() => {

    document.title = "A propos de Taqalid"

    if (inView) {
      control.start("visible");
    }

  }, [control, inView]);

  return (
    <div>

        <Header/>

        <HeaderBox/>

        <div className='c mt50 mb30'>
            <div className='flex ai-center'>

                <div className='col-5 p30'>
                    <h1 className='ff fz2'>À a propos de nous</h1>
                    <p className='fz11 M mt10 lh'>Taqalid, votre plateforme e-commerce au service de l’art et de l’artisanat algérien.</p>
                    <p className='fz11 mt5 lh'>Taqalid est une entreprise qui vient d’être créée, dont l’activité résidera dans le lancement 
                        d’une plateforme marketplace / e-commerce en ligne, proposant divers articles du monde de 
                        l’art et de l’artisanat algérien. 
                        L’objectif est de mettre en relation artistes et artisans algériens avec de potentiels clients via 
                        la création de boutiques virtuelles sur la plateforme.
                    </p>
                </div>
                <motion.div ref={ref} initial="hidden" animate={control} variants={variantR} className='col-5'>
                
                  <img src={ WEBROOT + "image/articles/zrabi.jpeg" } className="img " />
        
                </motion.div>
            </div>

            <div className='flex ai-center mt20'>
                <motion.div ref={ref} initial="hidden" animate={control} variants={variantL} className='col-5'>
                  
                  <img src={ WEBROOT + "image/web.png" } className="img " />
        
                </motion.div>
                <div className='col-5 p30'>
                    <h1 className='ff fz2'>Le fonctionnement de Taqalid</h1>
                    <p className='fz11 mt10 lh'>Notre plateforme permet l’achat et la vente de créations 
                      originales, artisanales et artistiques en Algérie. Elle met en relation les clients avec de 
                      talentueux artistes et artisans algériens. Ainsi, l’ensemble des articles proposés sont garantis 
                      100% made in DZ et sont en majorité faits main et personnalisables.
                    </p>
                </div>
              
            </div>

            <div className='flex ai-center mt20'>
                <div className='col-5 p30'>
                    <h1 className='ff fz2'>Achetez en toute sécurité</h1>
                   
                    <p className='fz11 mt10 lh'>Vous pouvez poser toutes les questions nécessaires lors de votre 
                      achat à nos artistes et artisans via la messagerie dédiée. Vos achats sont livrés chez vous ou 
                      au point de retrait de votre choix, et le paiement se fait à la livraison.
                    </p>
                </div>
                <motion.div ref={ref} initial="hidden" animate={control} variants={variantR} className='col-5'>
                <img src={ WEBROOT + "image/buy.jpg" } className="img " />
        
                </motion.div>
            </div>

            <div className='flex ai-center mt20'>
                <motion.div ref={ref} initial="hidden" animate={control} variants={variantL} className='col-5'>
                  <img src={ WEBROOT + "image/expo.jpeg" } className="img " />
        
                </motion.div>
                <div className='col-5 p30'>
                    <h1 className='ff fz2'>Participer à l’essor de la filière artisanale algérienne</h1>
                    <p className='fz11 mt10 lh'>En effectuant vos achats, vous 
                      soutenez et aidez des artistes et artisans algériens à exprimer leur créativité et à faire 
                      prospérer leur activité.
                    </p>
                </div>
              
            </div>
            
        </div>
        
        <Footer/>
    </div>
  )
}

export default Apropos