import React from 'react'
import { Footer, Header, HeaderBox } from '../../components/imports'
import { useEffect } from 'react'
import Func from '../../class/Func'
import { useNavigate } from 'react-router-dom'
import { useContext } from 'react'
import { Context } from '../../class/Context'
import { APP_NAME, AVATAR_ROOT } from '../../constants/general'
import User from '../../class/User'
import { useState } from 'react'
import { Aside } from '../../components/Aside'
import { useTranslation } from 'react-i18next'

function ParametresB() {

  const {t} = useTranslation()

  const navigate = useNavigate();

  const context = useContext(Context)

  const [editPassword, setEditPassword] = useState(false)

  const [loading, setLoading] = useState(false)

  const [infos, setInfos] = useState(false)
  
  const [flash, setFlash] = useState('')
  
  const modifierMotdepasse = async (e) =>{
  
      e.preventDefault()
  
      setLoading(true)
  
      let inputs = e.target
  
      const results = await User.modifierMotdepasse(inputs)
  
      setLoading(false)
  
      if(results.success){
  
          e.target.password_old.value = ''
          e.target.password.value = ''
          e.target.password_r.value = ''
  
          setFlash({type: 'succes', message: t('t251')})
  
      }else{
  
          setFlash({type: 'error', message: t('t252')})
  
      }
  
  }
  
  const myInfos = async () => {
  
    let results = await User.session(true)
  
    if(results.success){
  
        setInfos(results.data)
  
    }
  
  }

  useEffect(() => {

    Func.session(context, navigate)

    myInfos()

    document.title = t('t15') + " - " + APP_NAME

  }, [])

  return (

    <div>

      <Header/>

      <HeaderBox/>

      <div className='c flex mt80 mb30 m_mt_20'>
        
        <Aside/>

        <div className='col-6'>

          <h1 className='ff fz18 mb30 ta-center'>{t('t15')}</h1>

          {(flash !== '') && <p className={ flash.type }>{ flash.message }</p>}


             <form method="POST" className="form brca p30" onSubmit={(e) => modifierMotdepasse(e)} id='parametres'>

              <label>{t('t253')}</label>
              <input type="password" name="password_old" placeholder={t('t253')} required="true" className='bg7'/>
              
              <label>{t('t254')}</label>
              <input type="password" name="password" placeholder={t('t254')} required="true" className='bg7'/>
              
              <label>{t('t255')}</label>
              <input type="password" name="password_r" placeholder={t('t255')} required="true" className='bg7'/>

              <input type="submit" name="modifier" value={t('modifier')} className="btn bg3 cl1 hover-bg2"  onClick={ e => setEditPassword(!editPassword)}/>                     
              
            </form>
       
        </div>
    
      </div>

      <Footer/>
      
    </div>
  )
}

export default ParametresB