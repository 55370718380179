import { Link } from "react-router-dom"
import Func from "../class/Func"
import { useEffect, useState } from "react"
import Discussions from "../class/Discussions"
import Moment from "react-moment"
import { useTranslation } from "react-i18next"

const DiscussionsCard = () => {

    const {t} = useTranslation()

    const [discussions, setDiscussions] = useState(false)

    const mesDiscussions = async () => {

        let results = await Discussions.mes_discussions({limit: 12})

        if(results.success){

            setDiscussions(results.data)

        }else{

            setDiscussions(results.message)
        }

    }

    useEffect(() => {

        mesDiscussions()

    }, [])

    return (
        <div className='side brca mt20 col-28 ta-center bg7 disc_list'>
            <h2 className='ta-center cl3 brcb p20 fz15 M m_flex'> <span className='icon cl3 fz13 mr10'>N</span>{t('t285')}</h2>
            {
                discussions
                ?
                    typeof discussions == 'object'
                    ?

                    <ul>
                    
                    { discussions.map(element => <DiscussionCard data={element} key={ element.id }/>) }

                    </ul>

                    :
                    <p className='fz08'>{discussions}</p>
                :
                    <div className='flex jc-center mt50 mb50'>
                        <div className="loader"></div>
                    </div>
            }
        </div>
    )
}


const DiscussionCard = (props) => {

    const me = localStorage.getItem('idU')

    const discussionClick = (id) => {

        let elem = document.querySelector('#discussion_' + id)

        elem.classList.remove('fw7')

        if(elem.querySelector('.badge')) elem.querySelector('.badge').remove()

    }

    let badge = ''

    if(me == props.data.u1){

        if(props.data.new_u1 > 0) badge = <span className='badge bg3 cl1'>{ props.data.new_u1 }</span>

    }else{

        if(props.data.new_u2 > 0) badge = <span className='badge bg3 cl1'>{ props.data.new_u2 }</span>

    }

    return(

        <li>
            <Link to={"/discussion/" + props.data.id } className={ `flex ai-center jc-between pos-r cl2 ${badge == '' ? '' : ' fw7'}` } id={'discussion_' + props.data.id} onClick={() => discussionClick(props.data.id) }>
                <img src={ Func.avatar(props.data.avatar) } className='avatar-small'/>
                <h3 className='ml10 cl8 mt30 fz11 M fw6 name'>{ props.data.name } { badge }</h3>
                <p className='cl8 fz08'><Moment interval='1000' locale="fr" fromNow>{ props.data.date }</Moment></p>

            </Link>
        
        </li>
    )
}


const Message = (props) => {

    const me = localStorage.getItem('idU')

    return(

        <div>
            {

             props.data.sender == me

            ?

             <div className="left">

                <div className="message bg1">
                    <p>{ props.data.msg }</p>
                    <time className="fz08 flex-end mt5"><Moment interval='1000' locale="fr" fromNow>{ props.data.date }</Moment></time>

                </div>

             </div>   

            :

             <div className="message bg3-light">

                <p>{ props.data.msg }</p>
                <time className="fz08 flex-end"><Moment interval='1000' locale="fr" fromNow>{ props.data.date }</Moment></time>

             </div>  

            }
         
        </div>
    )
}

export { Message, DiscussionsCard }