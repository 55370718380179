import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Footer, Header, HeaderBox } from '../../components/imports'
import Func from '../../class/Func';
import { APP_NAME } from '../../constants/general';
import { useTranslation } from 'react-i18next';

function SuspendreBoutique() {

  const {t} = useTranslation()

  const navigate = useNavigate()

  const [get_params] = useSearchParams()
  
  const [closed, setClosed] = useState(false)

  const suspendre = async () => {
    
    const results = await Func.fetch("users/suspendre", {})

    if(results.success){

      let t_msg = t('t267')

      if(results.message == 'm2') t_msg = t('t268')

      Func.alert(t_msg)

      navigate(-1)

    }else{

      Func.alert("ERROR")

    }

  }

  useEffect(() => {

    let closed = get_params.get("closed")

    if(closed !== null) setClosed(true)

    document.title = t('t266') + " - " + APP_NAME

  }, []);

  return (
    <div>

      <Header/>

      <HeaderBox/>

      <div className='c flex ai-center jc-center mb30'>

        <div className='brca mt50 mb50 p30'>

          {

            closed == true

            ?

            <h1 className='ff fz18 mb30'>{t('t270')}</h1>

            :

            <h1 className='ff fz18 mb30'>{t('t269')}</h1>

          }

          <div className='flex jc-center'>

            <span className="btn bg3 cl1 hover-bg2 hover-cl1" onClick={() => suspendre()}>{t('oui')}</span>
            <span className="btn bg3 cl1 hover-bg2 hover-cl1" onClick={() => navigate(-1)}>{t('non')}</span>

          </div>

        </div>

      </div>
      
      <Footer/>
  </div>
  )
}

export default SuspendreBoutique