import React, { useEffect } from 'react'
import { Footer, Header, HeaderBox } from '../../../components/imports'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { APP_NAME } from '../../../constants/general'
import Func from '../../../class/Func'
import { useTranslation } from 'react-i18next'

function AnnulerCommande() {

  const {t} = useTranslation()

  const { id } = useParams()

  const navigate = useNavigate()

  const annuler = async (e) => {

    e.preventDefault()

    const results = await Func.fetch("annonces/commandes/annuler", {id: id, raison: e.target.raison.value})

    if(results.success){

      Func.alert(t('t50'))

      navigate('/commandes/1')

    }else{

      Func.alert("ERROR")

    }

  }

  useEffect(() => {

    document.title = t('t51') + " - " + APP_NAME

    window.scrollTo(0, 0)

} , [])

  return (
    <div>
      <Header/>

      <HeaderBox/>

      <div className='c flex ai-center jc-center mb30'>

        <form method="POST" className="form mt50 brca p50 m_mt_0" onSubmit={e => annuler(e)}>

          <h1 className='ff fz18 mb30'>{t('t51')}</h1>
  
          <label>{t('t52')}</label>
          
          <label className='label-radio mt30 m_mb_20'><input type="radio" name='raison' value={t('t54')} className="mr10" required={true} />{t('t54')}</label>
          <label className='label-radio m_mb_20'><input type="radio" name='raison' value={t('t55')} className="mr10 m_mb_0" />{t('t55')}</label>
          <label className='label-radio m_mb_20'><input type="radio" name='raison' value={t('t56')} className="mr10 m_mb_0" />{t('t56')}</label>
          <label className='label-radio'><input type="radio" name='raison' value={t('t57')} className="mr10 m_mb_0" />{t('t57')}</label>

          <p className='fz09 mb30'>{t('t53')}</p>

          <input type="submit" name="valider" value={t('envoyer')} className="btn bg2 cl1 hover-bg3 hover-cl1 mb30"/>

        </form>

      </div>

      <Footer/>
      
  </div>

  )
}

export default AnnulerCommande