import React, { useState, useEffect, useContext } from 'react';

import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import { Header, Footer, HeaderBox } from '../../components/imports'

import { APP_NAME } from '../../constants/general'

import { Context } from '../../class/Context'

import Func from '../../class/Func'

import User from '../../class/User'

function ConnexionFacebook() {

    const [ get_params ] = useSearchParams()

    const context = useContext(Context)

    const navigate = useNavigate()

    const [flash, setFlash] = useState({})

    const sendCode = async () => {

        const code = get_params.get("code")

        if(code == null){

            setFlash({message: "Impossible de se connecter pour l'instant.", type: "error"})

            return
            
        }

        let results = await User.facebookConnect(code)

        if(results.success){

            setTimeout(() => {

                Func.saveSession(results.data, results.likes, context.setSession)

                navigate('/panier')

            }, 500)

        }else{

            setFlash({message: results.message, type: 'error'})
                         
        }
    
    }

    useEffect(() => {

        sendCode(context.setSession)

        document.title = "Connexion avec Facebook - " + APP_NAME

    }, [])


    return (
        <div>

            <Header />

            <div className="login">

                <HeaderBox />

                <div className='pos-r'>

                    <div className="col-4 m0a pt80 pb80">

                        <div className="p40 ta-center pos-r">

                            <h1 className='mb30 fw4'>Connexion avec Facebook</h1>

                            <div className="btn_social_connect mb30">

                                {

                                    flash.type

                                    ?

                                        <>

                                            <p className={ flash.type }>{ flash.message }</p>

                                            <Link to={'/'} className='btn bg3 cl1'>Accéder</Link>

                                        </>

                                    :

                                        <div className='flex jc-center mt50 mb50'>
                                            <div className="lds-dual-ring col"></div>
                                        </div>

                                }

                            </div>
                        </div>

                    </div>

                </div>

            </div>

            <Footer />

        </div>
    )

}

export default ConnexionFacebook