import { API_ROOT, API_SECRET } from '../constants/general';

class User {

    static register_pro = (input) => {

        let data = fetch(API_ROOT + "register_pro", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                name: input.name.value,
                email: input.email.value,
                tele: input.tele.value,
                password: input.password.value,
                token: input.token.value
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

    static preregisterB = (input) => {

        let data = fetch(API_ROOT + "register", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                name: input.name.value,
                email: input.email.value,
                tele: input.tele.value,
                nameB : input.nameB.value,
                fb : input.fb.value,
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

    static registerClient = (input) => {

        let data = fetch(API_ROOT + "register", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                name: input.name.value,
                email: input.email.value,
                tele: input.tele.value,
                password: input.password.value,
                wilaya: input.wilaya.value,
                adresse: input.adresse.value
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

    static login = (input) => {
        
        let data = fetch(API_ROOT + "login", {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({
                api_secret: API_SECRET,
                login: input.email.value,
                password: input.pass.value,
            })
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data
    }

    static session = (infos = false) => {
        let data = fetch(API_ROOT + "session", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token'),
                infos: infos
            })
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data
    }

//     static validerEmail = (email, token) => {
//         let data = fetch(API_ROOT + "users/validerEmail", {
//             method: "POST",
//             headers: {
//                 "Content-Type": "application/json"
//             },
//             body: JSON.stringify({
//                 api_secret: API_SECRET,
//                 email: email,
//                 token: token
//             })
//         }).then(function (response) {
//             return response.json()
//         }).then(function (data) {

//             return data

//         })

//         return data
//     }

//     static renvoyerValidation = () => {
//         let data = fetch(API_ROOT + "users/renvoyerValidation", {
//             method: "POST",
//             headers: {
//                 "Content-Type": "application/json"
//             },
//             body: JSON.stringify({
//                 api_secret: API_SECRET,
//                 id_user: localStorage.getItem('idU'),
//                 app_token: localStorage.getItem('app_token')
//             })
//         }).then(function (response) {
//             return response.json()
//         }).then(function (data) {

//             return data

//         })

//         return data
//     }

    static profile = (id) => {
        let data = fetch(API_ROOT + "users/profile", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id: id
            })
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data
    }

    static connected = async (setSession) => {

        if (localStorage.getItem('idU') !== undefined && localStorage.getItem('app_token') !== undefined) {

            const results = await this.session()

            if (!results.success) {

                localStorage.removeItem('idU')
                localStorage.removeItem('app_token')
                localStorage.removeItem('name')
                localStorage.removeItem('email')
                localStorage.removeItem('tel')

                setSession(false)

            } else {

                setSession(true)

            }

            return results.success

        } else {

            return false
        }
    }

    static photoUpload = (file) => {

        var formdata = new FormData()
        formdata.append('image', file)
        formdata.append('id_user', localStorage.getItem('idU'))
        formdata.append('app_token', localStorage.getItem('app_token'))
        
        let data = fetch(API_ROOT + "users/photoUpload", {
            method: "POST",
            body: formdata
        }).then(response => response.json())
        .then(data => {

            return data

        })

        return data
    }

    static coverUpload = (file) => {

        var formdata = new FormData()
        formdata.append('image', file)
        formdata.append('id_user', localStorage.getItem('idU'))
        formdata.append('app_token', localStorage.getItem('app_token'))
        
        let data = fetch(API_ROOT + "users/coverUpload", {
            method: "POST",
            body: formdata
        }).then(response => response.json())
        .then(data => {

            return data

        })

        return data
    }

    static modifierInformations = (input) => {

        let data = fetch(API_ROOT + "users/modifierInformations", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token'),

                name: input.name.value,
                wilaya: input.wilaya.value,
                adresse: input.adresse.value,
                tele: input.tele.value,
             
            })
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data
    }

    static modifier_Boutique = (input) => {
        
        let livraison_autre_partenaire =( input.autre_partenaire == undefined ) ? "" :  input.autre_partenaire.value
        let livraison_moi_meme =( input.livraison_moi_meme == undefined ) ? 1 :  input.livraison_moi_meme.value
        let politiquetext =( input.politiquetext == undefined ) ? "" :  input.politiquetext.value

        let data = fetch(API_ROOT + "users/modifierBoutique", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token'),

                name: input.name.value,
                tele: input.tele.value,
                email: input.email.value,
                description: input.description.value,
                wilaya: input.wilaya.value,
                livraison: input.livraison.value,
                autre_partenaire: livraison_autre_partenaire,
                livraison_moi_meme: livraison_moi_meme,
                delaislivraison: input.delaislivraison.value,
                politique: input.politique.value,
                politiquetext: politiquetext
            })
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data
    }

    static tarif = (input) => {
        let data = fetch(API_ROOT + "users/livraisonAjout", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token'),

                dom: input.dom.value,
                relais: input.relais.value,
                wilaya: input.wilaya.value,
                moimeme: input.moimeme.checked,

            })
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data
    }

    static getTarif = (input) => {
        let data = fetch(API_ROOT + "users/livraisonRecup", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token'),

                wilaya: input,

            })
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data
    }

    static getTarifGlobal = (boutique_id) => {
        let data = fetch(API_ROOT + "users/livraisonTarifs", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                boutique_id : boutique_id

            })
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data
    }

    static LivraisonPrix = (boutique_id) => {
        let data = fetch(API_ROOT + "users/livraisonPrix", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token'),

                wilaya : localStorage.getItem('wilaya'),
                boutique_id: boutique_id
     

            })
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data
    }


    static modifierMotdepasse = (input) => {
        let data = fetch(API_ROOT + "users/modifierMotdepasse", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token'),
                old_password: input.password_old.value,
                password: input.password.value,
                password_r: input.password_r.value
            })
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data
    }

//     // Social Login

    static googleConnect = (code) => {

        let data = fetch(API_ROOT + "users/connect/google", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                code: code
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

    static facebookConnect = (code) => {

        let data = fetch(API_ROOT + "users/connect/facebook", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                code: code
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

    // Avis ---------

    static avis = (id = 0) => {

        let data = fetch(API_ROOT + "users/avis", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token'),
                id: id
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            console.log(data)

            return data

        })

        return data

    }

    static ajouterAvis = (id, input) => {
        let data = fetch(API_ROOT + "users/ajouterAvis", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token'),
                id: id,
                note: input.note.value,
                avis: input.avis.value
            })
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data
    }

    // Reset password -----------

    static resetPasswordLink = (email) => {
        let data = fetch(API_ROOT + "users/resetPasswordLink", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                email: email
            })
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data
    }

    static checkTokenPassword = (email, token) => {
        let data = fetch(API_ROOT + "users/checkTokenPassword", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                email,
                token
            })
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data
    }

    static resetPassword = (inputs, id, token) => {
        let data = fetch(API_ROOT + "users/resetPassword", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                password: inputs.password.value,
                password_verif: inputs.password_verif.value,
                id: id,
                token: token
            })
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data
    }

}


export default User