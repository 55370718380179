import React, { useContext, useEffect, useState } from 'react';
import { WEBROOT, APP_NAME, AVATAR_ROOT } from '../constants/general';
import { Link, useNavigate } from "react-router-dom";
import Func from '../class/Func';
import { Context } from '../class/Context';
import Annonces from '../class/Annonces';
import Categories from '../class/Categories';
import User from '../class/User';
import { useTranslation } from 'react-i18next'

const Header = () => {

  const { t } = useTranslation()

  const context = useContext(Context)

  const navigate = useNavigate()

  const [search, setSearch] = useState({})

  const [panier, setPanier] = useState(0)

  const [badge, setBadge] = useState(0)

  const debounce = (func, timeout = 400) => {

    let timer;

    return (...args) => {

      clearTimeout(timer)

      timer = setTimeout(() => { func.apply(this, args); }, timeout)
    }
  }

  const searchFunc = async (e) => {

    let query = e.target.value

    let results = await Annonces.search(query)

    if (results.success) {

      console.log(results)

      if (results.data.length > 0) {

        setSearch(results.data)

        setTimeout(() => {
          console.log(search)
        }, 1000);

      } else {

        setSearch({})
      }

    }

  }

  const menu = () => {

    document.body.classList.toggle('mn')

  }

  const processChange = debounce((e) => searchFunc(e))

  const messages = async () => {

    let results = await User.session()

    if (results.success) setBadge(results.messages)

  }

  useEffect(() => {

    if (localStorage.getItem('panier') == null) {

      localStorage.setItem('panier', '[]')

    } else {

      if (JSON.parse(localStorage.getItem("panier")).length !== null) setPanier(JSON.parse(localStorage.getItem("panier")).length)

    }

    if (context.session) messages()

  }, [])

  return (

    <header dir="ltr">

      <div className="pos-r flex c ai-center head">

        <div>
          <span className='menu-res bg3 cl1 pc_d_none m_d_block menu_open_on' onClick={menu}><svg width="34" height="34" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 18L20 18" stroke="#fff" strokeWidth="2" strokeLinecap="round" /><path d="M4 12L20 12" stroke="#fff" strokeWidth="2" strokeLinecap="round" /><path d="M4 6L20 6" stroke="#fff" strokeWidth="2" strokeLinecap="round" /></svg></span>
          <span className='icon menu-res bg3 cl1 pc_d_none menu_open_off' onClick={menu}>x</span>

          <Link to={'/'} className="logo-link"><img src={WEBROOT + "image/logo.png"} alt={APP_NAME} className="logo" /></Link>
        </div>

        <form action='/search' method="GET" className='search-box flex col-5 m_d_none'>
          <input type="search" name="query" placeholder={t('t5')} onChange={e => processChange(e)} autoComplete='off' />
          <input type="submit" value="w" className='icon cl3' />

          {
            search.length > 0

            &&

            <div className='results'>

              {search.map(s =>

                s.t == 'a'

                  ?

                  <Link to={'/article/' + s.url} key={s.id}>{s.titre}</Link>

                  :

                  <Link to={'/boutique/' + s.url} key={s.id}><span className='icon'>c</span> {s.titre}</Link>

              )}

            </div>
          }

        </form>

        <div className='flex ai-center fz18 jc-center m_flex'>

          {
            context.session

              ?
              <>

                {
                  localStorage.getItem("user_type") == 2

                    ?

                    <>
                      <Link to={'/discussions'} className='link pos-r'><span className='icon cl2 hover-cl3'>C</span><div className='circle2 bg9'><p className='cl1 fz06'>{badge}</p></div></Link>
                      <div className='pos-r user'>
                        <span><img src={Func.avatar(localStorage.getItem('avatar'))} className="avatar-small" /></span>

                        <ul className='compte'>
                          <li><Link to={'/boutique/' + localStorage.getItem("idU")} className='link' ><span className='icon mr10'>u</span>{t('t7')}</Link></li>
                          <li><Link to={'/commandes/0'} className='link'><span className='icon mr10'>-</span>{t('t8')}</Link></li>
                          <li><Link to={'/mesArticles'} className='link'><span className='icon'>&</span>{t('t9')}</Link></li>
                          <li><Link to={'/ajouter-article'} className='link'><span className='icon'>&</span>{t('t10')}</Link></li>
                          <li><Link to={'/discussions'} className='link'><span className='icon'>N</span>{t('t11')}</Link></li>
                          <li><Link to={'/modifierboutique'} className='link'><span className='icon'>E</span>{t('t12')}</Link></li>
                          <li><Link to={'/mes-notes-avis'} className='link'><span className='icon'>g</span>{t('t13')}</Link></li>
                          <li><Link to={'/mes-factures'} className='link'><span className='icon'>&</span>{t('t14')}</Link></li>
                          <li><Link to={'/parametres_Boutique'} className='link'><span className='icon'>r</span>{t('t15')}</Link></li>
                          <li><Link to={'/statistiques'} className='link'><span className='icon mr10'>3</span>{t('t16')}</Link> </li>
                          <li><Link to={'/'} className='link' onClick={() => Func.logout(context, navigate)}><span className='icon'>P</span>{t('t17')}</Link></li>

                        </ul>

                      </div>

                    </>

                    :

                    <>
                      <Link to={'/discussions'} className='link pos-r'><span className='icon cl2 hover-cl3'>C</span><div className='circle2 bg9'><p className='cl1 fz06'>{badge}</p></div></Link>
                      <Link to={'/favoris'}><span className='icon cl2 hover-cl3'>h</span></Link>
                      <Link to={'/panier'} className='pos-r'><span className='icon cl2 hover-cl3'>c</span><div className='circle2 bg8'><p className='cl1 fz06'>{(JSON.parse(localStorage.getItem("panier")).length) !== null ? (JSON.parse(localStorage.getItem("panier")).length) : 0}</p></div></Link>

                      <div className='pos-r user'>

                        <span><img src={Func.avatar(localStorage.getItem('avatar'))} className="avatar-small" /></span>

                        {/* Client compte */}

                        <ul className='compte'>
                          <li><Link to={'/commandes/0'} className='link'><span className='icon'>m</span>{t('t8')}</Link></li>
                          <li><Link to={'/favoris'} className='link'><span className='icon mr10'>h</span>{t('t18')}</Link></li>
                          <li><Link to={'/parametres'} className='link'><span className='icon'>r</span>{t('t19')}</Link></li>
                          <li><Link to={'/'} className='link' onClick={() => Func.logout(context, navigate)}><span className='icon'>P</span>{t('t17')}</Link></li>
                        </ul>
                      </div>
                    </>
                }


              </>
              :
              <>
                <Link to={'/panier'} className='pos-r'><span className='icon cl2 hover-cl3'>c</span><div className='circle2 bg8'><p className='cl1 fz06'>{panier}</p></div></Link>
                <Link to={'/connexion'} className='cl2 bg7 btn hover-bg3 hover-cl1'>{t('t6')}</Link>
              </>
          }


        </div>

        <form action='/search' method="GET" className='search-box flex col-5 pc_d_none m_d_block'>
          <input type="search" name="query" placeholder={t('t5')} onChange={e => processChange(e)} autoComplete='off' />
          <input type="submit" value="w" className='icon cl3' />

          {
            search.length > 0

            &&

            <div className='results'>

              {search.map(s =>
                
                s.t == 'a'

                  ?

                  <Link to={'/article/' + s.url} key={s.id}>{s.titre}</Link>

                  :

                  <Link to={'/boutique/' + s.url} key={s.id}><span className='icon'>c</span> {s.titre}</Link>

              )}

            </div>
          }

        </form>

      </div>

    </header>
  );

}

const Footer = () => {

  const navigate = useNavigate()

  const { t, i18n } = useTranslation()

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng)
    navigate(0)
  }

  useEffect(() => {
    document.body.dir = i18n.dir()
  }, [i18n, i18n.language])

  return (
    <footer className="bg1 brct pb20">

      <div className='flex  jc-center c pt10'>

        <div className='col-2 fz15'>
          <Link to={'/'} className="logo-link"><img src={WEBROOT + "image/logo.png"} alt={APP_NAME} className="logo " /></Link>
        </div>

        <div className='col-3'>
          <h2 className='cl3 mb10'>{t('t20')}</h2>
          <Link to={'/page/a-propos'}><span className='link hover-cl3'>{t('t21')}</span></Link>
          <Link to={'/inscription'}><span className='link hover-cl3'>{t('t22')}</span></Link>
        </div>

        <div className='col-3'>
          <h2 className='cl3'>{t('t23')}</h2>
          <Link to={'/page/conditions-generales-d-utilisation-de-la-marketplace-taqalid'}><span className='link hover-cl3'>{t('t24')}</span></Link>
          <Link to={'/page/politique-de-confidentialite'}><span className='link hover-cl3'>{t('t25')}</span></Link>
          <Link to={'/page/politique-de-retours-et-echanges'}><span className='link hover-cl3'>{t('t26')}</span></Link>
          <Link to={'/page/politique-de-resolution-des-litiges'}><span className='link hover-cl3'>{t('t27')}</span></Link>
          {/* <Link to={'/page/informations-sur-les-cookies-de-taqalid'}><span className='link hover-cl3'>{ t('t28') }</span></Link> */}
        </div>

        <div className=' col-2'>
          <h2 className='cl3'>{t('t29')}</h2>
          <div className='flex ai-center jc-start fz18 follow'>
            <Link to={'https://www.facebook.com/profile.php?id=100092217831083'} target='_blank'><span className='icon link hover-cl3 mr10'>5</span></Link>
            <Link to={'https://www.instagram.com/taqalid.dz'} target='_blank'><span className='icon link  hover-cl3 mr10'>4</span></Link>
            <Link to={'https://www.tiktok.com/@taqalid.dz'} target='_blank'><span className='link hover-cl3 mr10 tik'><svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"> <path stroke="none" d="M0 0h24v24H0z" fill="none" /> <path d="M9 12a4 4 0 1 0 4 4v-12a5 5 0 0 0 5 5" /> </svg></span></Link>
          </div>

          <h2 className='cl3'>{t('t30')}</h2>

          <div className='flex ai-center jc-start fz18 follow mb20 m_pl_5'>

            <p class="btn_lng" onClick={() => changeLanguage("fr")}>FR</p>
            <p class="btn_lng" onClick={() => changeLanguage("en")}>EN</p>
            <p class="btn_lng" onClick={() => changeLanguage("ar")}>AR</p>

          </div>

        </div>

      </div>

      <div className='c brct pt20'>
        <p>© EURL TAQUALID AND CRAFTS</p>
      </div>

    </footer>
  );

}

const HeaderBox = () => {

  const { i18n } = useTranslation()

  const [isNavExpanded, setIsNavExpanded] = useState(true);

  const [categories, setCategories] = useState(false)

  const getCategorie = async () => {

    let results = await Categories.getCategories()

    setCategories(results.data)

    sessionStorage.setItem('categories', JSON.stringify(results.data))

    if (sessionStorage.categories !== undefined) {

      setCategories(JSON.parse(sessionStorage.categories))

    } else {

      let results = await Categories.getCategories()

      setCategories(results.data)

      sessionStorage.setItem('categories', JSON.stringify(results.data))

    }

  }

  const openCats = e => {

    e.target.parentNode.classList.toggle('open')

  }

  const openCat = e => {

    e.target.parentNode.classList.toggle('open')

  }

  useEffect(() => {

    getCategorie()

  }, [])

  return (

    <nav className={`bg1 nav ${i18n.language == 'ar' ? 'ar_dir' : ''}`}>

      <ul className='c'>

        {

          categories !== false

          &&

          categories.map((categorie, i) =>

            <>
              {

                i == 6

                &&

                <li className='cl1 hover-cl3 icon btn btn_show' onClick={e => openCats(e)}>
                  2
                </li>

              }

              <li key={i} className={`pos-r flex${i >= 6 ? ' t_show' : ''}${(i == 4 || i == 5 || i == 10 || i == 11) ? ' pos_right' : ''}`}>

                <Link to={'/categorie/' + categorie.url} className='link'>{categorie.name}</Link>

                <span className='icon arrow_cat' onClick={e => openCat(e)}>2</span>

                {

                  categorie.sous > []

                  &&

                  <ul>

                    {categorie.sous.map(souscategorie => <li key={souscategorie.url}><Link to={'/categorie/' + souscategorie.url} className='ss-link'>{souscategorie.name}</Link></li>)}

                  </ul>

                }

              </li>

              {

                i == 11

                &&

                <span className={`cl3 icon btn pos-r m_d_none flex${i >= 6 ? ' t_show' : ''}${(i == 4 || i == 5 || i == 10 || i == 11) ? ' pos_right' : ''}`}>2</span>

              }

            </>

          )

        }

      </ul>

    </nav>

  )

}

const HeaderBoutiquePublic = (props) => {

  const { t } = useTranslation()

  return (
    <div dir="ltr">
      <div className='head-b'>
        <img src={Func.cover(props.data.cover)} className=" head-b" />

        <div className='av flex ai-center flex-start'>
          <img src={Func.avatar(props.data.avatar)} className=" avatar" />
          <h1 className='cl8 mt30 ml20 text-Ca m_mt_10 m_mb_20'>{props.data.name} </h1>
          <Link to={'/envoyer-message/' + props.data.idU} className='btn fz09 bg3 cl1 ml20 mt30'><span className='icon t_upper'>C</span> {t('b3')}</Link>
        </div>

        <div className='topv'>
          <div className='flex flex-start ai-center mt10 m_flex'>
            {/* <p className='cl8 mr5'>50 articles vendues  <span className='rm'>|</span></p> */}
            <div className='fz11 cl3 mr5 flex ai-center'>
              {Func.note(props.data.note)} <span className='ml10 fz08'>({props.data.nbr_note} {t('t31')})</span>
            </div>
            {/* <div className='cl8 mr5'> <span className='rm'>|</span> <span className='icon mr5 cl3'>i</span> Top vendeur</div> */}
          </div>
        </div>
      </div>

      <div className='space'></div>
    </div>
  )

}

const HeaderBoutique = () => {

  return (
    <div className='m_d_none' dir="ltr">
      <div className='head-b'>
        <img src={Func.cover(localStorage.getItem('cover'))} className="head-b cover_img" />

        <div className='av flex ai-center flex-start'>
          <img src={Func.avatar(localStorage.getItem('avatar'))} className="avatar" />
          <h1 className='cl8 mt30 ml20 text-Ca'>{localStorage.getItem('name')} </h1>

        </div>

        <div className='topv'>
          <div className='flex flex-start ai-center mt10 m_flex'>
          </div>
        </div>
      </div>

      <div className='space'></div>
    </div>
  )

}


const ToolbarCommande = (props) => {

  const { t } = useTranslation()

  return (

    <div className='toolbar brca mt80 col-10 ta-center bg7 br20 m_mt_0'>
      <p className='ta-center cl3 fz12 p20'>{t('t8')}</p>
      <div className="brc3 brc1solid "></div>
      <ul className='fz08 m_list_elem m_d_block'>
        <li className={props.url == 0 && 'bg3 cl1'}> <Link to={'/commandes/0'} className='ss-link'>{t('t32')} {props.pastille.attente && props.pastille.attente > 0 && <span class="cl1 bg9 p5 br8 fz07">{props.pastille.attente}</span>}</Link></li>
        <li className={props.url == 2 && 'bg3 cl1'}> <Link to={'/commandes/2'} className='ss-link'>{t('t33')} {props.pastille.expedition && props.pastille.expedition > 0 && <span class="cl1 bg9 p5 br8 fz07">{props.pastille.expedition}</span>}</Link> </li>
        <li className={props.url == 3 && 'bg3 cl1'}> <Link to={'/commandes/3'} className='ss-link'>{t('t34')} {props.pastille.livraison && props.pastille.livraison > 0 && <span class="cl1 bg9 p5 br8 fz07">{props.pastille.livraison}</span>}</Link> </li>
        <li className={props.url == 4 && 'bg3 cl1'}> <Link to={'/commandes/4'} className='ss-link'>{t('t35')}</Link></li>
        <li className={props.url == 6 && 'bg3 cl1'}> <Link to={'/commandes/6'} className='ss-link'>{t('t36')}</Link></li>
        <li className={props.url == 1 && 'bg3 cl1'}> <Link to={'/commandes/1'} className='ss-link'>{t('t37')}</Link></li>
        <li className={props.url == 5 && 'bg3 cl1'}> <Link to={'/commandes/5'} className='ss-link fw6'>{t('t38')} {props.pastille.reclamation && props.pastille.reclamation > 0 && <span class="cl1 bg9 p5 br8 fz07">{props.pastille.reclamation}</span>}</Link></li>
      </ul>
    </div>

  )

}

export { Header, Footer, HeaderBox, HeaderBoutiquePublic, HeaderBoutique, ToolbarCommande };