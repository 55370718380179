import React, { useEffect, useState } from 'react'
import { Footer, Header, HeaderBox } from '../../components/imports'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { APP_NAME } from '../../constants/general'
import Func from '../../class/Func'
import { useTranslation } from 'react-i18next'

function NoteAvisReponse() {

  const {t} = useTranslation()

  const navigate = useNavigate()

  const { id } = useParams()

  const repondre = async (e, id) => {
    
    e.preventDefault()

    const results = await Func.fetch("users/avisReponse", {id: id, reponse: e.target.reponse.value})

    if(results.success){
      
      Func.alert(t('t249'))
      
      navigate('/mes-notes-avis')

    }else{

      Func.alert(t('t250'))

    }

}

  useEffect(() => {

    document.title = t('t248') + " - " + APP_NAME

    window.scrollTo(0, 0)

} , [])

  return (
    <div>
      <Header/>

      <HeaderBox/>

      <div className='c flex ai-center jc-center mb30'>

        <form method="POST" className="form mt50 brca p50 col-5" onSubmit={e => repondre(e, id)}>

          <h1 className='ff fz18 mb30'>{t('t248')}</h1>
          
          <textarea name='reponse' placeholder="Réponse ..." required={true}></textarea>
          
          <div className='flex jc-start ai-center'>

            <input type="submit" name="valider" value={t('envoyer')} className="btn bg3 cl1 hover-bg3 hover-cl1 m0"/>

            <span className='btn bg2 cl1 ml10 m_d_block m_mt_10 m_ml_0' onClick={ e => navigate(-1) }>{t('annuler')}</span>

          </div>

        </form>

      </div>

      <Footer/>
      
  </div>

  )
}

export default NoteAvisReponse