import React, { useEffect } from 'react'
import { Header, Footer, HeaderBox } from '../components/imports'
import { Link, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import User from '../class/User'
import { APP_NAME } from '../constants/general'
import Func from '../class/Func'
import { Context } from '../class/Context'
import { useContext } from 'react' 
import { useTranslation } from 'react-i18next'

function Preinscriprion() {

  const {t} = useTranslation()

  const navigate = useNavigate()

  const [error, setError] = useState('')

  const envoyer = async (e) => {

    e.preventDefault()

    let input = e.target
    
    const results = await Func.fetch("users/preinscription", {name: input.name.value, email: input.email.value, tele: input.tele.value, name_boutique: input.name_boutique.value, social: input.social.value})

    if(results.success){

      Func.alert(t('t451'))

      navigate('/')

    }else{

      setError(results.message)

    }

  }

  useEffect(() => {

    document.title = t('t450') + " - " + APP_NAME

    window.scrollTo(0, 0)

  }, []);

  return (
    <div>
       <Header/>

       <HeaderBox/>

       <div className='c flex ai-center jc-center'>

        <div className='pt50 pb50 cnx'>

            <h1 className='cl8 M fz2'>{t('t452')}</h1>

            <p className='cl8 pt30 pb50 ta-center'>{t('t453')}</p>

            <form action="" method="POST" className="form p20m" onSubmit={ e => envoyer(e) }>

              {( error  !== "") && <p className='error'>{error}</p>}

              <label className='mt10 mb10'>{t('t382')}</label>
              <input type="text" name="name" placeholder={t('t382')} required="true" className='bg7' />

              <label className='mt10 mb10'>{t('t213')}</label>
              <input type="email" name="email" placeholder={t('t213')} required="true" className='bg7' />

              <label className='mt10 mb10'>{t('t214')}</label>
              <input type="tel" name="tele" placeholder={t('t214')} required="true" className='bg7' /> 

              <label className='mt10 mb10'>{t('t441')}</label>
              <input type="text" name="name_boutique" placeholder={t('t441')} required="true" className='bg7' /> 

              <label className='mt10 mb10'>{t('t454')}</label>
              <input type="text" name="social" placeholder={t('t454')} required="true"className='bg7' /> 
              
              <input type="submit" name="submit" value={t('envoyer')} className="btn bg3 cl1 hover-bg5 hover-cl2"/> 
                                  
            </form>

        </div>

       </div>
       <Footer/> 
    </div>
  )
}

export default Preinscriprion