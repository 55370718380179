import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Footer, Header, HeaderBoutique, HeaderBox } from '../../components/imports'
import { Aside } from '../../components/Aside'
import { APP_NAME, WEBROOT } from '../../constants/general'
import { Context } from '../../class/Context'
import Func from '../../class/Func'
import Moment from 'react-moment'
import { useTranslation } from 'react-i18next'


function NotesAvis() {

  const {t} = useTranslation()

  const navigate = useNavigate()

  const context = useContext(Context)

  const [avis, setAvis] = useState([])

  const getAvis = async () => {
    
    const results = await Func.fetch("users/avis", {})

    if(results.success){

      setAvis(results.data)

    }else{

      setAvis(t('t247'))

    }

}

  useEffect(() => {

    Func.session(context, navigate)

    document.title = t('t246') + " - " + APP_NAME

    getAvis()

  }, []);

  return (

    <div>
      <Header/>

      <HeaderBox/>

      <HeaderBoutique/>

      <div className='c flex mb30'>
        
        <Aside/>
        
        <div className='col-10 p20m'>
          
          <h1 className='ff fz18 mb20 mt80 m_mt_0'>{ t('t246') }</h1>

          {

            avis.length > 0

            ?

              typeof avis == 'string'

              ?

              <p>{ avis }</p>

              :

              avis.map(a =>

                <div className='avis brca mt40 col-10'>

                  <div className='flex'>

                    <span className='flex ai-center flex-start pos-r'>
                      <img src={ Func.avatar(a.avatar) } className=" avatar-small" />
                      <p className='ml10 cl8 mt30 fz11 M fw6 name '>{ a.name }</p>
                    </span>

                    <p className='cl8 mt30 fz09 M fw5'><Moment format='DD/MM/YYYY à HH:II' locale="fr">{ a.date }</Moment></p>

                  </div>

                  { Func.note(a.note) }

                  <p className='mt10'>{ a.text }</p>

                  {

                    a.reponse == null

                    ?

                    <div className='flex jc-end m_flex'>

                      <Link to={"/reponse-avis/" + a.idA} className='btn bg3 cl1 fz09'>{ t('t104') }</Link>

                    </div>

                    :

                    <p className='mt20 fz09'><span className='fw6'>{ t('t209') }</span> : { a.reponse }</p>

                  }
                
                </div>

              )

            :

            <div className='flex jc-center mt50 mb50'><div className="lds-dual-ring col"></div></div>

          }

        </div>
      </div>

      <Footer/>

  </div>

  )
}

export default NotesAvis