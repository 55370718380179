import React, { useEffect, useState } from 'react'

import Func from '../class/Func'
import { useTranslation } from 'react-i18next'

function VariationsEdit(props) {

    const {t} = useTranslation()

    let stock = props.stock

    let array_aa = props.array

    let array = props.array

    let setArray = props.setArray

    // const [array, setArray] = useState([])

    const [option, setOption] = useState({option_id: 0, parent_id: 0, type: 0, value: null, prix: '/', qtt: '/'})
  
    const [ajouterVariations, setAjouterVariations] = useState(0)
    const [ajouterSousVariations, setAjouterSousVariations] = useState(0)

    const [editVariation, setEditVariation] = useState(false)
    const [editVariationType, setEditVariationType] = useState(0)
    const [editVariationValue, setEditVariationValue] = useState(0)

    const [variation, setVariation] = useState(0)

    const [addPrix, setAddPrix] = useState(false)

    const [sousVariation, setSousVariation] = useState(false)

    const [index, setIndex] = useState()
  
    const [bolin, setBolin] = useState(false)

    const [terminerVariations, setTerminerVariations] = useState(false)
  
    const {type, prix, qtt, value} = option

    function addinputdata(){
  
      if(type === "" || value === null){
  
        Func.alert(t('t289'))

      }else{

        setArray([... array, {type, value, prix, qtt, sous_options: []}])
  
        setOption({type: type, value: null, prix: '/', qtt: '/', sous_options: []})
   
      }
   
    }

    function ajouter_sous_option(){

        if(variation === "" || value === null){
  
            Func.alert(t('t289'))
    
          }else{

            let last_array = array

            last_array[sousVariation.id].sous_options.push(option)

            setArray(last_array)
    
            setOption({type: type, value: null, prix: '/', qtt: '/', sous_options: []})
            
        }
   
    }

    function addChildren(id, type, value){

        setOption({type: 0, value: null, prix: '/', qtt: '/', sous_options: []})

        setSousVariation({id, type, value})
     
    }
  
    function delete_option(i){
    
        let total = [...array]
    
        total.splice(i, 1)
    
        setArray(total)
  
    }
  
    function update_option(type, value, i){

        setEditVariation({i})

        setEditVariationType(type)
        setEditVariationValue(value)
  
    }

    function delete_sous_option(i, id){

        delete array[i].sous_options[id]

        setVariation(!variation)

        setVariation(!variation)
    
    }
    
    function update_sous_option(type, value, i, id){
  
        setEditVariation({i, id})

        setEditVariationType(type)
        setEditVariationValue(value)
    
    }
    
    function modifier_variation(){

        let new_array = array

        if(editVariation.id == null){

            new_array[editVariation.i].type = editVariationType
            new_array[editVariation.i].value = editVariationValue

        }else{

            new_array[editVariation.i].sous_options[editVariation.id].type = editVariationType
            new_array[editVariation.i].sous_options[editVariation.id].value = editVariationValue
            
        }
        
        setArray(new_array)

        setEditVariation(false)
        setEditVariationType(false)
        setEditVariationValue(false)
   
    }
  
    function updateinfo(){}

    function editPrixVar(type, id, value){

        let new_array = array

        if(type == null){

            new_array[id].prix = value

        }else{

            new_array[type].sous_options[id].prix = value

        }
        
        setArray(new_array)

    }

    function editQttVar(type, id, value){

        let new_array = array

        if(type == null){

            new_array[id].qtt = value

        }else{

            new_array[type].sous_options[id].qtt = value
            
        }
        
        setArray(new_array)

    }
  
    function data(e){
  
      setOption({...option,[e.target.name]:e.target.value})
  
    }

    function ajouterSousVariationsFunc(value){

        setAjouterSousVariations(value)

        if(value == 1){
            addChildren(0, array[0].type, array[0].value )
        }else{
            setSousVariation(false)
        }

    }

    useEffect(() => {

        setArray(array_aa)

        if(array_aa.length > 0){

            setAjouterVariations(1)

            if(array_aa[0].sous_options.length > 0){

                setSousVariation(true)
                setAjouterSousVariations(true)
                setTerminerVariations(true)

            }
    
        }

        }, [])

    return (

        <>

            <div>
                <label className='fz15'>{t('t289')}</label>
                <label className='label'><input type="radio" name='ajouterVariations' className="mr10" value={1} onChange={(e) => setAjouterVariations(1)} checked={ajouterVariations == 1 ? true : false}/>{t('oui')}</label>
                <label className='label'><input type="radio" name='ajouterVariations' className="mr10" value={0} onChange={(e) => setAjouterVariations(0)} checked={ajouterVariations == 0 ? true : false}/>{t('non')}</label>
            </div>

            {

                ajouterVariations == 1

                &&

                <>

                    {

                        // Ajouter variation parentes -----------------------------------------------

                        sousVariation == false

                        &&

                        <>

                            <label className='fz15'>{t('t290')}</label>
                            
                            <select name="type" required="true" className='bg7' onChange={data} value={option.type || ""}> 

                                <option value='0'>{t('t291')}</option>
                                <option value='t292'>{t('t292')}</option>
                                <option value='t293'>{t('t293')}</option>
                                <option value='t294'>{t('t294')}</option>
                                <option value='t295'>{t('t295')}</option>
                                <option value='t296'>{t('t296')}</option>
                                <option value='t297'>{t('t297')}</option>
                                <option value='t298'>{t('t298')}</option>
                                <option value='t299'>{t('t299')}</option>

                            </select>

                            {

                                type !== 0

                                &&

                                <>

                                    {

                                        type == "t293" || type == "t294"

                                        ?  

                                        <div className='col-2'><input type="color" name="value" className='w' onChange={data} value={option.value || ""}/></div>

                                        :
                                        
                                        <input type="text" name="value" placeholder={t('t302') + " : " + t(type)} onChange={data} value={option.value || "" }/>               

                                    }

                                    <span className='btn bg3 cl1 hover-bg2 mr10 m_d_block ta-center m_mb_10 m_mr_0' onClick={!bolin ? addinputdata : updateinfo }>{ !bolin ? t('t303') : t('modifier') }</span>
                                    <span className='btn bg3 cl1 hover-bg2 m_d_block ta-center' onClick={() => setTerminerVariations(true) }>{t('t304')}</span>

                                </>

                            }

                        </>

                    }

                    {

                        // Terminer l'ajout des variations -----------------------------------------

                        terminerVariations

                        &&

                        <div className='mt30'>
                            <label className='fz15'>{t('t305')}</label>

                            <label className='label'><input type="radio" name='ajouterSousVariations' className="mr10" value={1} onChange={(e) => ajouterSousVariationsFunc(1)} checked={ajouterSousVariations == 1 ? true : false}/>{t('oui')}</label>
                            <label className='label'><input type="radio" name='ajouterSousVariations' className="mr10" value={0} onChange={(e) => ajouterSousVariationsFunc(0)} checked={ajouterSousVariations == 0 ? true : false}/>{t('non')}</label>
                        </div>

                    }

                    {

                        // Ajouter sous variations ------------------------------------------------

                        sousVariation.type

                        &&

                            <>

                                <label className='fz15 mt30'>{t('t306')} {t(sousVariation.type)}, { sousVariation.type == 't293' || sousVariation.type == 't294' ? <span className="circl" style={{backgroundColor: sousVariation.value, display: 'inline-block', margin: '0'}}></span> : <span>{sousVariation.value}</span> }</label>
                            
                                <select name="type" required="true" className='bg7' onChange={data} value={option.type || ""}> 

                                    <option value='0'>{t('t291')}</option>
                                    <option value='t292'>{t('t292')}</option>
                                    <option value='t293'>{t('t293')}</option>
                                    <option value='t294'>{t('t294')}</option>
                                    <option value='t295'>{t('t295')}</option>
                                    <option value='t296'>{t('t296')}</option>
                                    <option value='t297'>{t('t297')}</option>
                                    <option value='t298'>{t('t298')}</option>
                                    <option value='t299'>{t('t299')}</option>

                                </select>

                                {

                                    type !== 0

                                    &&

                                    <>

                                        {

                                            type == "t293" || type == "t294"

                                            ?  

                                            <div className='col-2'><input type="color" name="value" className='w' onChange={data} value={option.value || ""}/></div>

                                            :
                                            
                                            <input type="text" name="value" placeholder={t('t302')+ " : " + type} onChange={data} value={option.value || "" }/>               

                                        }
                                        
                                        <span className='btn bg3 cl1 hover-bg2 mr40' onClick={ ajouter_sous_option }>{t('t307')}</span>


                                    </>

                                }
                            
                            </>

                    }

                    {

                    // Modifier une variation ----------------------------------------

                    editVariation

                    &&

                        <>

                            <label className='fz15 mt30'>{t('t308')} { t(editVariationType) } : { editVariationValue }</label>
                            
                            <select name="type" required="true" className='bg7' onChange={e => setEditVariationType(e.target.value)} value={editVariationType || ""}> 

                                <option value='0'>{t('t291')}</option>
                                <option value='t292'>{t('t292')}</option>
                                <option value='t293'>{t('t293')}</option>
                                <option value='t294'>{t('t294')}</option>
                                <option value='t295'>{t('t295')}</option>
                                <option value='t296'>{t('t296')}</option>
                                <option value='t297'>{t('t297')}</option>
                                <option value='t298'>{t('t298')}</option>
                                <option value='t299'>{t('t299')}</option>

                            </select>

                            {

                                editVariationType !== 0

                                &&

                                <>

                                    {

                                        editVariationType == "t293" || editVariationType == "t294"

                                        ?  

                                        <div className='col-2'><input type="color" name="value" className='w' onChange={e => setEditVariationValue(e.target.value)} value={editVariationValue || ""}/></div>

                                        :
                                        
                                        <input type="text" name="value" placeholder={t('t302') + " : " + editVariationType} onChange={e => setEditVariationValue(e.target.value)} value={editVariationValue || "" }/>               

                                    }
                                    
                                    <span className='btn bg3 cl1 hover-bg2 mr40' onClick={ modifier_variation }>{t('modifier')}</span>


                                </>

                            }
                        
                        </>

                    }

                    {

                        // Afficher le tableau des variations ----------------------------------------

                        array.length > 0
                        
                        &&
                        
                        <>

                        <div className='grid5 mt50 m_grid5'>
                            <p className='p10 brcb'>{t('t309')}</p> 
                            <p className='p10 brcb'>{t('t310')}</p> 
                            <p className='p10 brcb'>{t('prix')} (DA)</p> 
                            { stock == 0 && <p className='p10 brcb'>{t('quantite')}</p> }
                            <p className='p10 brcb'></p>
                        </div> 

                        {

                            array.map((item, i)=> {

                                return (

                                    <div key={'vars_' + i}>
                                    
                                        <div className='grid5 sous_vars_elem m_grid5'>
                                            <p className='p10 brcb'>
                                                { t(item.type) }
                                            </p>

                                            <p className='p10 brcb'>

                                                {
                                                        
                                                    item.type == "t293" || item.type == "t294"
                                                    
                                                    ?
                                                    
                                                    <p className="circl" style={{backgroundColor: item.value}}></p>

                                                    :

                                                    <>{item.value}</>
                                                
                                                }

                                            </p>

                                            <p className='p10 brcb'>{

                                                ajouterSousVariations

                                                ?

                                                item.prix

                                                :

                                                <input type='number' defaultValue={item.prix == '/' ? 0 : item.prix} required={true} min={0} onChange={ e => editPrixVar(null, i, e.target.value) }/>

                                            }</p>

                                            {
                                            
                                            stock == 0 
                                            
                                            &&

                                                <p className='p10 brcb'>{

                                                    ajouterSousVariations

                                                    ?

                                                    item.qtt

                                                    :

                                                    <input type='number' defaultValue={item.qtt == '/' ? 0 : item.qtt} required={true} min={0} onChange={ e => editQttVar(null, i, e.target.value) }/>

                                                }</p>

                                            }

                                            <div className='flex flex-start ai-center brcb'>

                                                { ajouterSousVariations ? <span className="cl1 bg9 fz08 cp mr20 p10 btn" title="Ajouter une sous option" onClick={() => addChildren(i, item.type, item.value )}>{t('t303')}</span> : <></>}
                                                <span className="icon cl9 fz13 cp mr20 p10" onClick={() => update_option(item.type, item.value, i)}>E</span>
                                                <span className="icon cl9 fz13 cp p10" onClick={() => delete_option(i)}>o</span>

                                            </div>

                                        </div>
                                    
                                        {

                                            item.sous_options.map((sub_item, id)=> {

                                                return(

                                                    <div key={'sous_var_' + id} className='grid5 bg7 sous_vars_elem m_grid5'>

                                                        <p className='p10 brcb'>
                                                            - { t(sub_item.type) }
                                                        </p>

                                                        <p className='p10 brcb'>

                                                            {
                                                                    
                                                                sub_item.type == "t293" || sub_item.type == "t294"
                                                                
                                                                ?
                                                                
                                                                <p className="circl" style={{backgroundColor: sub_item.value}}></p>

                                                                :

                                                                <>{sub_item.value}</>
                                                            
                                                            }

                                                        </p>

                                                        <p className='p10 brcb'><input type='number' defaultValue={sub_item.prix == '/' ? 0 : sub_item.prix} required={true} min={0} onChange={ e => editPrixVar(i, id, e.target.value) }/></p>
                                                        { stock == 0 && <p className='p10 brcb'><input type='number' defaultValue={sub_item.qtt == '/' ? 0 : sub_item.qtt} required={true} min={0} onChange={ e => editQttVar(i, id, e.target.value) }/></p> }

                                                        <div className='flex flex-start ai-center brcb'>

                                                            <span className="icon cl7 fz13 cp mr40 p10">2</span> 
                                                            <span className="icon cl9 fz13 cp mr20 p10" onClick={() => update_sous_option(sub_item.type, sub_item.value, i, id)}>E</span> 
                                                            <span className="icon cl9 fz13 cp p10" onClick={() => delete_sous_option(i, id)}>o</span>

                                                        </div>
                                                    </div>
                                                )

                                            })

                                        }

                                    </div>
                                
                                )
                            
                            })

                        }

                        </>

                    }
                </>

            }

        </>
  
    )
}

export default VariationsEdit