import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Footer, Header, HeaderBox } from '../../components/imports'
import Func from '../../class/Func';
import { APP_NAME } from '../../constants/general';
import { useTranslation } from 'react-i18next';

function DonnerAvis() {

  const {t} = useTranslation()
  
  const id = useParams()

  const navigate = useNavigate()

  const [note, setNote] = useState(5);
  const [avis, setAvis] = useState('');

  const envoyerAvis = async (e) => {

    e.preventDefault()
    
    const results = await Func.fetch("annonces/commandes/ajouterAvis", {id: id.id, note, avis})

    if(results.success){

      Func.alert(t('t356'))

      navigate('/commandes/0')

    }else{

      Func.alert(results.message)

    }

  }

  useEffect(() => {

    document.title = t('t355') + " - " + APP_NAME

    window.scrollTo(0, 0)

  }, []);

  return (
    <div>

      <Header/>

      <HeaderBox/>

      <div className='c flex ai-center jc-center mb30'>

        <form method="POST" className="form mt50 brca p20 m20m" onSubmit={ e => envoyerAvis(e) }>

          <h1 className='ff fz18 mb30'>{t('t355')}</h1>

          <label>{t('t357')}</label>

          <select name='note' defaultValue={note} onChange={e => setNote(e.target.value)}>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
          </select>

          <label className='mt30 mb10'>{t('t358')}</label>
          
          <textarea type="text" name="avis" placeholder={t('t359')} className='bg7' defaultValue={avis} onChange={e => setAvis(e.target.value)}/> 

          <input type="submit" name="publier" value={t('envoyer')} className="btn bg2 cl1 hover-bg3 hover-cl1"/>

        </form>

      </div>
      
      <Footer/>
  </div>
  )
}

export default DonnerAvis