import { API_ROOT, API_SECRET } from '../constants/general'

class Annonces {

    static home = () => {

        let data = fetch(API_ROOT + "annonces/acceuil", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                lng: localStorage.getItem('i18nextLng')
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

    static annoncesByIdCommander = (id) => {

        let data = fetch(API_ROOT + "annonces/annonceByIdCommander", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id: id
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

    static Commanderannonces= (id) => {

        let data = fetch(API_ROOT + "annonces/Commanderannonces", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id: id
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

    static panier = (panier) => {

        let data = fetch(API_ROOT + "annonces/panier", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token'),
                panier
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

    static annoncesByLot = (lot) => {

        let data = fetch(API_ROOT + "annonces/annonceByLot", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                lot: lot
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

    static mesAnnonces = () => {

        let data = fetch(API_ROOT + "annonces/mesAnnonces", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token')
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

    static AutresArticlesbyUser = (id_user) => {

        let data = fetch(API_ROOT + "annonces/byUser", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                user_id: id_user,
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

    static getPhotosPublier = () => {
        let data = fetch(API_ROOT + "annonces/getPhotosPublier", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token')
            })
        }).then(function (response) {
            return response.json()
        }).then(function (data) {
            return data
        })
        return data
    }

    static getVideosPublier = () => {
        let data = fetch(API_ROOT + "annonces/getVideoPublier", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token')
            })
        }).then(function (response) {
            return response.json()
        }).then(function (data) {
            return data
        })
        return data
    }

    static publier = (inputs, array, occasion) => {

        let sous_categorie = (inputs.souscategorie == undefined) ? "" : inputs.souscategorie.value

        let prix_personnaliser =( inputs.prixpersonnaliser == undefined ) ? 0 :  inputs.prixpersonnaliser.value

        let conception =( inputs.conception == undefined ) ? "" : inputs.conception.value

        let quantity =( inputs.quantity == undefined ) ? 0 : inputs.quantity.value

        let obligatoire =( inputs.obligatoire == undefined ) ? 0 : inputs.obligatoire.value

        let personnalisation =( inputs.personnalisation == undefined ) ? "" : inputs.personnalisation.value

        let descpersonnaliser =( inputs.descpersonnaliser == undefined ) ? "" : inputs.descpersonnaliser.value

        let poids = ( inputs.poids == undefined || inputs.poids == "") ? 0 : inputs.poids.value

        let livraison_indiv =( inputs.livraison_indiv == undefined ) ? 0 : inputs.livraison_indiv.value

        let livraison_special =( inputs.livraison_tarif_special == undefined ) ? 0 : inputs.livraison_tarif_special.value
        
        let livraison_special_prix =( inputs.livraison_special_prix == undefined ) ? 0 : inputs.livraison_special_prix.value

        let handmade = inputs.handmade.checked == true ? 1 : 0;
        let livraisongratuite = inputs.livraisongratuite.checked == true ? 1 : 0;
        let emballage = inputs.emballage.checked == true ? 1 : 0;

        let data = fetch(API_ROOT + "annonces/publier", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token'),

                titre: inputs.titre.value,
                categorie: inputs.categorie.value,
                souscategorie: sous_categorie,
                description: inputs.description.value,
                typeproduit: inputs.typeproduit.value,
                quantity: quantity,
                conception: conception,
                prix: inputs.prix_produit.value,
                prixpromotion: inputs.prixpromotion.value,
                obligatoire: obligatoire,
                personnalisation: personnalisation,
                descpersonnaliser: descpersonnaliser,
                emballage: emballage,
                occasion: occasion,
                prixpersonnaliser: prix_personnaliser,
                livraisongratuite: livraisongratuite,
                handmade: handmade,
                options: array,
                poids: poids,
                livraison_indiv: livraison_indiv,
                livraison_special: livraison_special,
                livraison_special_prix: livraison_special_prix

            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {
            
            return data

        })

        return data

    }

    static modifier_annonce = (id, inputs, array, occasions) => {

        console.log('Catégories (Problème, Note : Khaled) ----------------- ')
        console.log(inputs.categorie.value)
        console.log(inputs.souscategorie.value)

        let sous_categorie = (inputs.souscategorie == undefined) ? 0 : inputs.souscategorie.value

        let prix_personnaliser =( inputs.prixpersonnaliser == undefined ) ? 0 :  inputs.prixpersonnaliser.value

        let conception =( inputs.conception == undefined ) ? "" : inputs.conception.value

        let quantity =( inputs.quantity == undefined ) ? 0 : inputs.quantity.value

        let obligatoire =( inputs.obligatoire == undefined ) ? 0 : inputs.obligatoire.value

        let personnalisation =( inputs.personnalisation == undefined ) ? "" : inputs.personnalisation.value

        let descpersonnaliser =( inputs.descpersonnaliser == undefined ) ? "" : inputs.descpersonnaliser.value

        let poids = ( inputs.poids == undefined || inputs.poids == "") ? 0 : inputs.poids.value

        let livraison_indiv =( inputs.livraison_indiv == undefined ) ? 0 : inputs.livraison_indiv.value

        let livraison_special =( inputs.livraison_tarif_special == undefined ) ? 0 : inputs.livraison_tarif_special.value
        
        let livraison_special_prix =( inputs.livraison_special_prix == undefined ) ? 0 : inputs.livraison_special_prix.value

        let handmade = inputs.handmade.checked == true ? 1 : 0;
        let emballage = inputs.emballage.checked == true ? 1 : 0;
        let livraisongratuite = inputs.livraisongratuite.checked == true ? 1 : 0;

        let commercialisation = ( inputs.commercialisation == undefined ) ? 0 : inputs.commercialisation.value;

        let data = fetch(API_ROOT + "annonces/modifierAnnonce", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token'),

                annonce_id: id,
                titre: inputs.titre.value,
                categorie: inputs.categorie.value,
                souscategorie: sous_categorie,
                description: inputs.description.value,
                typeproduit: inputs.typeproduit.value,
                quantity: quantity,
                conception: conception,
                prix: inputs.prix.value,
                prixpromotion: inputs.prixpromotion.value,
                personnalisation: personnalisation,
                obligatoire: obligatoire,
                descpersonnaliser: descpersonnaliser,
                prixpersonnaliser: prix_personnaliser,
                handmade: handmade,
                emballage: emballage,
                livraisongratuite: livraisongratuite,
                occasion: occasions,
                options: array,
                poids: poids,
                livraison_indiv: livraison_indiv,
                livraison_special: livraison_special,
                livraison_special_prix: livraison_special_prix,
                commercialisation: commercialisation
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {
            
            return data

        })

        return data

    }

    static annoncesById = (id) => {
        let data = fetch(API_ROOT + "annonces/annonceById", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id: id
            })
        }).then(function (response) {
            return response.json()
        }).then(function (data) {
            return data
        })
        return data
    }

    static annonceSingle = (id) => {
        let data = fetch(API_ROOT + "annonces/annonceSingle", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id: id
            })
        }).then(function (response) {
            return response.json()
        }).then(function (data) {
            return data
        })
        return data
    }

    static annoncesByIdEdit = (id) => {
        let data = fetch(API_ROOT + "annonces/annonceByIdEdit", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token'),
                id: id
            })
        }).then(function (response) {
            return response.json()
        }).then(function (data) {
            return data
        })
        return data
    }

    static annoncesByCategorie = (idC, option) => {

        let data = fetch(API_ROOT + "annonces/byCategorie", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                idC: idC,
                option: option,
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

    static annoncesByOccasion = (idO) => {

        let data = fetch(API_ROOT + "annonces/byOccasion", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                idO: idO
            })
            
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

    static search = (query) => {

        let data = fetch(API_ROOT + "annonces/search", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                query:query
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {
            
            return data

        })

        return data

    }

    static searchPage = (query, options) => {

        let data = fetch(API_ROOT + "annonces/searchPage", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                query:query,
                options: options
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {
            
            return data

        })

        return data

    }

    static photoUpload = (file, id = 0) => {
        
        var formdata = new FormData()
        formdata.append('image', file)
        formdata.append('id_user', localStorage.getItem('idU'))
        formdata.append('app_token', localStorage.getItem('app_token'))
        formdata.append('id', id)
        
        let data = fetch(API_ROOT + "annonces/photoUpload", {
            method: "POST",
            body: formdata
        }).then(response => response.json())
        .then(data => {

            return data

        })

        return data
    }

    static videoUpload = (file, id = 0) => {

        var formdata = new FormData()
        formdata.append('video', file)
        formdata.append('id_user', localStorage.getItem('idU'))
        formdata.append('app_token', localStorage.getItem('app_token'))
        formdata.append('id', id)
        
        let data = fetch(API_ROOT + "annonces/videoUpload", {
            method: "POST",
            body: formdata
        }).then(response => response.json())
        .then(data => {
        
            return data

        })

        return data
    }

    static supprimerPhoto = (id) => {

        let data = fetch(API_ROOT + "annonces/supprimerPhoto", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token'),
                id: id
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            console.log(data)
            
            return data

        })

        return data

    }

    static supprimerVideo = (id) => {

        let data = fetch(API_ROOT + "annonces/supprimerVideo", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token'),
                id: id
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            console.log(data)
            
            return data

        })

        return data

    }
    
    static ajoutFavoris = (id) => {

        let data = fetch(API_ROOT + "annonces/ajoutFavoris", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token'),
                id:id
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

    static favoris = () => {

        let data = fetch(API_ROOT + "annonces/favoris", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token')
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

    // Achats

    static achats = (option) => {

        let data = fetch(API_ROOT + "annonces/achats", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token'),
                option: option
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

    static ventes = (option) => {

        let data = fetch(API_ROOT + "annonces/ventes", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token'),
                option: option
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

}

export default Annonces