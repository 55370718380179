import React, { useEffect, useState } from 'react'
import { Footer, Header, HeaderBox } from '../../../components/imports'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { APP_NAME } from '../../../constants/general'
import Func from '../../../class/Func'
import { useTranslation } from 'react-i18next'

function Remboursement() {

  const {t} = useTranslation()

  const navigate = useNavigate()

  const { id } = useParams()

  const [get_params] = useSearchParams()
  
  const [integral, setIntegral] = useState(1)

  const [montant, setMontant] = useState(0)

  const rembourser = async (e, id) => {
    
    e.preventDefault()

    const results = await Func.fetch("annonces/commandes/rembourssement", {id, montant})

    if(results.success){

      if(results.message == 'S1'){

        Func.alert(t('t58'))

        navigate('/commandes/1')

      }else{

        Func.alert(t('t59'))

        navigate('/commandes/4')

      }

    }else{

      if(results.message == 'E1'){
        Func.alert(t('t60'))
      }else{
        Func.alert('ERROR')
      }

    }

  }

  useEffect(() => {

    let montant = get_params.get("montant")

    document.title = t('t61') + " - " + APP_NAME

    window.scrollTo(0, 0)

} , [])

  return (
    <div>
      <Header/>

      <HeaderBox/>

      <div className='c flex ai-center jc-center mb30'>

        <form method="POST" className="form mt50 brca p50 col-5 m_mt_0" onSubmit={e => rembourser(e, id)}>

          <h1 className='ff fz18 mb30'>{t('t61')}</h1>
  
          <label>{t('t62')}</label>

          <div className='label'><input type="radio" name='integral' className="mr10" value={1}  required checked={integral == 1 ? true : false} onChange={e => setIntegral(1)}/>{t('oui')}</div>
          <div className='label'><input type="radio" name='integral' className="mr10" value={0} required checked={integral == 0 ? true : false} onChange={e => setIntegral(0)}/>{t('non')}</div>

          {

            integral == 0

            &&

            <>
            
              <label>{t('t63')}</label>

              <input type='number' name='price' placeholder={t('t64')} onChange={e => setMontant(e.target.value)}/>

            </>

          }
          
          <div className='flex jc-start ai-center mt10'>

            <input type="submit" name="valider" value={t('envoyer')} className="btn bg3 cl1 hover-bg3 hover-cl1 m0"/>

            <span className='btn bg2 cl1 ml10 m_d_block m_ml_0 m_mt_10' onClick={ e => navigate(-1) }>{t('annuler')}</span>

          </div>

        </form>

      </div>

      <Footer/>
      
  </div>

  )
}

export default Remboursement