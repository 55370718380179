import React from 'react'
import { Footer, Header, HeaderBox } from '../components/imports'
import { WEBROOT } from '../constants/general'
import { useTranslation } from 'react-i18next'

function Page_404() {

  const {t} = useTranslation()
  
  return (
    <>
      <Header/>

      <HeaderBox/>

      <div className='flex ai-center jc-center c flex-col mb80 img404'>
        
        <img src={ WEBROOT + "image/404.png" } />

        <h1 className='ta-center fw7'>{t('t449')}</h1>

      </div>

      <Footer/>
    </>

  )
}

export default Page_404