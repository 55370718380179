import React, { useEffect, useState } from 'react'
import { Header, Footer, HeaderBox } from '../components/imports'
import { Link, redirect, useNavigate, useSearchParams } from 'react-router-dom'
import { APP_NAME } from '../constants/general'
import User from '../class/User'
import { useContext } from 'react'
import { Context } from '../class/Context'
import Func from '../class/Func'
import { useTranslation } from 'react-i18next'
import wilaya from '../Json/wilaya.json'

function CommanderInvite() {

  const {t} = useTranslation()

  const [get_params] = useSearchParams()

  const navigate = useNavigate()

  const [links, setLinks] = useState(false)

  const [red, setRed] = useState('/')

  const [error, setError] = useState('')
  const [createAccount, setCreateAccount] = useState(false)

  const context = useContext(Context)
  
  const getLinks = async () => {

    const results = await Func.fetch("users/connect/links", false)

    setLinks(results)

  }

  const Commander = async (e, setError, navigate) => {

    e.preventDefault()

    let pass = true

    JSON.parse(localStorage.panier).map(elem => {

        if(elem.delivery == '2' && (elem.pointsrelais == undefined || elem.pointsrelais == null || elem.pointsrelais == 0)){

            Func.alert(t('t338'))

            pass = false

            return false

        }

    })

    if(!pass) return false
  
    let input = e.target

    if( input.name.value !== "" && input.email.value !=="" &&  input.tele.value !== "" && input.adresse.value !== ""){

      let checkbox_password = input.checkbox_password

      let password = null

      if(checkbox_password.checked){

        if(input.password.value !== input.password_2.value){

          setError(t('t436'))
    
          window.scrollTo(0, 0)
    
          return false

        }

        password = input.password.value

      }

      let user = {
        name: input.name.value,
        email: input.email.value,
        tele: input.tele.value,
        adresse: input.adresse.value,
        password: password,
        wilaya: localStorage.getItem('wilaya')
      }

      let results = await Func.fetch("annonces/panierInvite", {user: JSON.stringify(user), panier: localStorage.getItem('panier')}, false)

      if(results.success){
        
        localStorage.panier = '[]'
        
        if(results.session){
          
          Func.alert(t('t339'))
          
          Func.saveSession(results.data, results.likes, context.setSession)
          
          navigate('/commandes/0')
          
        }else{
          
          Func.alert("Votre commande a été transmise à la boutique avec succès. Vous recevrez des notifications par e-mail à chaque étape du suivi pour connaître l'évolution de votre commande.")

          navigate('/')

        }

      }else{

        setError(results.message)

      }
  
    }else{

      setError(t('t418'))

    }

    window.scrollTo(0, 0)
      
  }

  useEffect(() => {

    document.title = 'Commander en tant que invité' + t('t417') + " - " + APP_NAME
    
    window.scrollTo(0, 0)

    getLinks()

    if(get_params.get("r") !== null) setRed('/' + get_params.get("r"))

  }, [])

  return (
    <div>

      <Header/>

      <HeaderBox/>
      
      <div className='c flex ai-center jc-center cnx'>

        <div className='pt100 pb100 col-6 m_p10'>

            <h1 className='cl8 ff fz3'>{t('t419')}</h1>

            {(error !== '') && <p className="error">{error}</p>}

            {

              links

              ?

                  <div className='grid2 mt20 mb20'>
                      <Link to={ links.facebook_url } className="btn bg3 cl1 flex ai-center jc-center m_flex"><svg className="mr10" width="30" height="30" viewBox="0 0 192 192" xmlns="http://www.w3.org/2000/svg" fill="none"><path stroke="#fff" stroke-linecap="round" stroke-width="12" d="M96 170c40.869 0 74-33.131 74-74 0-40.87-33.131-74-74-74-40.87 0-74 33.13-74 74 0 40.869 33.13 74 74 74Zm0 0v-62m30-48h-10c-11.046 0-20 8.954-20 20v28m0 0H74m22 0h22"/></svg> {t('t420')}</Link>
                      <Link to={ links.google_url } className="btn bg3 cl1 flex ai-center jc-center m_flex"><svg className="mr10" width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.5475 8.30327C14.6407 7.49361 13.4329 7 12.1089 7C9.28696 7 7 9.23899 7 12C7 14.761 9.28696 17 12.1089 17C15.5781 17 16.86 14.4296 17 12.4167H12.841" stroke="#fff" stroke-width="1.5"/><path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg> {t('t421')}</Link>
                  </div>
              :

                  <div className='flex jc-center mt50 mb50'>
                      <div className="lds-dual-ring col"></div>
                  </div>

            }

            <div className='flex jc-center'>
              <Link to={ `/connexion${get_params.get("r") !== null ? '?r=' + get_params.get("r") : ''}` } className="btn bg3 cl1 flex ai-center jc-center m_flex col-5" style={{height:'50px'}}><span className='icon fz15 mr10'>U</span> Se connecter avec mon compte</Link>
            </div>

            <form method="POST" className="form mt30" onSubmit={(e) => Commander(e,setError,navigate)}>

                <label className='mb10'>{t('t382')}</label>
                
                <input type="text" name="name" placeholder={t('t382')} required={true} className='bg7'/>
                
                <label className='mb10'>{t('t213')}</label>
                <input type="email" name="email" placeholder={t('t213')}  required={true} className='bg7'/>

                <label className='mt10 mb10'>{t('t214')}</label>
                <input type="tel" name="tele" placeholder={t('t214')}  required={true} className='bg7'/> 

                <div className='grid2'>

                  <div>
                    <label className='mb10'>{t('t196')}</label>
                    <select name="wilaya" required={true} className='bg7' disabled>

                      {

                        wilaya.map((getwilaya, index)  => (

                          <option value={getwilaya.id} key={index} selected={localStorage.getItem('wilaya') == getwilaya.id ? true : false}>{getwilaya.name}</option>

                        ))
                      }

                    </select>
                  </div>

                  <div>
                    <label className='mb10'>{t('adresse')}</label>
                    <input type="text" name="adresse" placeholder={t('adresse')}  required={true} className='bg7'/>
                  </div>

                </div>

                <div className='flex jc-start ai-start mb10 ml20 m_flex'>
                  <input type="checkbox" name='checkbox_password' className='checkbox col-1' onChange={e => setCreateAccount(e.target.checked)}/>
                  <div className='ml10 ta-start col-9'>Créer un compte en passant la commande</div>
                </div>

                {
                  createAccount

                  &&

                  <>
                  
                    <label className='mt10 mb10'>{t('t423')}</label>
                    <input type="password" name="password" placeholder={t('t423')} required={true} className='bg7'/>

                    <label className='mt10 mb10'>{t('t435')}</label>
                    <input type="password" name="password_2" placeholder={t('t435')} required={true} className='bg7'/>

                  </>
                }

                <div className='flex jc-start ai-start mb10 ml20 m_flex'>
                  <input type="checkbox" name='checkbox_conditions'  required={true} className='checkbox col-1'/>
                  <div className='ml10 ta-start col-9'>{t('t442')} <Link to={'/page/conditions-generales-d-utilisation-de-la-marketplace-taqalid' } className="cl3 fw5 d-inline">{t('t443')}</Link></div>
                </div>

                <div className='flex jc-start ai-start ml20 m_flex'>
                  <input type="checkbox" name='checkbox_politique' required={true} className='checkbox col-1'/>
                  <div className='ml10 ta-start col-9'>{t('t444')} <Link to={'/page/politique-de-confidentialite'} className="cl3 fw5">{t('t445')}</Link></div>
                </div>
              
              <input type="submit" name="log" value={t('t354')} className="btn bg3 cl1 hover-bg2 col-3 mb30"/>

            </form>

        </div>

      </div>

      <Footer/> 
  
    </div>
  )
}

export default CommanderInvite