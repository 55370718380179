import React, { useState, useEffect, useContext, useRef  } from 'react'
import { useNavigate, useParams} from 'react-router-dom'
import { Footer, Header, HeaderBox } from '../../components/imports'
import { APP_NAME } from '../../constants/general'
import { Context } from '../../class/Context';
import Func from '../../class/Func';
import { firebase_db } from '../../class/F_base'
import { collection, addDoc , onSnapshot, query, where, limit, orderBy } from 'firebase/firestore';
import Discussions from '../../class/Discussions';
import {DiscussionsCard, Message } from '../../components/chat';
import { useTranslation } from 'react-i18next';


function Discussion() {

    const {t} = useTranslation()

    const { id } = useParams()

    const [discussion, setDiscussion] = useState(false)

    const [messages, setMessages]= useState([])

    const navigate = useNavigate();

    const context = useContext(Context)

    const scroll = useRef()

    const getDiscussion = async () => {

        let results = await Discussions.discussion(id)

        console.log(results)

        if(results.success){
            
            setDiscussion(true)

            read_messages(results.discussion.id)

        }else{

            navigate('/404')

        }
    
    }

    const add_message = async (e) => {

        e.preventDefault()

        if(!discussion) return

        let message_input = e.target.message

        let message_input_value = message_input.value

        if (message_input.value.trim() === "") return

        let message = {
            id: id,
            sender: localStorage.getItem('idU'),
            msg: message_input.value,
            date: Date.now()
        }

        const docRef = await addDoc(collection(firebase_db, "messages"), message)
        
        message_input.value = ''
        
        message_input.focus()
        
        scroll.current.scrollIntoView({ behavior: "smooth" })
        
        if(docRef.id !== undefined) Discussions.envoyer_message(id, message_input_value)

        message_input_value = ''

    }

    const read_messages = async (id) => {

        //if(!discussion) return
        
        const q = query(collection(firebase_db, "messages"), where("id", "==", id), orderBy("date", "desc"), limit(15));

        onSnapshot(q, (querySnapshot) => {

            const messages_list = []

            querySnapshot.forEach((doc) => {
                
                messages_list.push({ ...doc.data(), id: doc.id })

            })

            if(messages_list.length == 0){

                setMessages(t('t402'))

            }else{
                
                let messages_list_reverse = messages_list.reverse()
                setMessages(messages_list_reverse)
                
            }

            setTimeout(() => {
                scroll.current.scrollIntoView({ behavior: "smooth" })
            }, 200)

        })

    }

    useEffect(() => {

        Func.session(context, navigate) 

        getDiscussion()

        document.title = t('t11') + " - " + APP_NAME
    
    }, [id])

  return (

    <div>

        <Header/>

        <HeaderBox/>

        <div className='flex c disc_page'>

            <DiscussionsCard/>

            <div className='col-7 bg7 mt20 br20 mb10 m_mt_0'>

                <h2 className='p20 bg1 pc_d_none m_d_block m_flex ai-center' onClick={ e => navigate(-1) }><span className='icon btn2 bg3 cl1'>0</span> Discussions</h2>
      
                <div className='discussion'>
                    
                    {

                        messages.length == 0

                    ?

                        <div className='flex jc-center pt100'><div className='loader'></div></div>

                    :

                        typeof messages === 'string'

                    ?

                        <div className='flex jc-center pt100'>{ messages }</div>

                    :

                     messages.map(message => <Message data={message} key={ message.id }/>)

                    } 
                    
                    <span ref={scroll}></span>


          
                </div>
                
            </div>

        </div>

        <form method='POST' className='form2 c flex-end col-6 m_mb_20' onSubmit={ (e) => add_message(e) } autoComplete="off">
            <input type="text" name="message" placeholder={t('t400')} className='bg7 mr10'/>
            <input type="submit" name="send" value={t('envoyer')} className="btn2 bg3 cl1 hover-bg2 hover-cl1"/>
        </form>

    
    </div>
  )
}

export default Discussion