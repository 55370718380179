import React, { useEffect, useState } from 'react'
import { Footer, Header, HeaderBox } from '../../../components/imports'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { APP_NAME } from '../../../constants/general'
import Func from '../../../class/Func'
import { useTranslation } from 'react-i18next'

function RetirerProduit() {

  const {t} = useTranslation()

  const navigate = useNavigate()

  const { id } = useParams()

  const retirer = async (e, id) => {
    
    e.preventDefault()

    const results = await Func.fetch("annonces/commandes/retirerProduit", {id: id})

    console.log(results)

    if(results.success){
      
      Func.alert(t('t65'))
      
      navigate('/commandes/0')

    }else{

      Func.alert(t('t66'))

    }

}

  useEffect(() => {

    document.title = t('t67') + " - " + APP_NAME

    window.scrollTo(0, 0)

} , [])

  return (
    <div>
      <Header/>

      <HeaderBox/>

      <div className='c flex ai-center jc-center mb30'>

        <div className="form mt50 brca p50 col-5 m_mt_0">

          <h1 className='ff fz18 mb30'>{ t('t68') }</h1>
          
          <p className='fz09 mb10'>{ t('t69') }</p>
          <p className='fz09 mb30'>{ t('t70') }</p>

          <div className='flex jc-start ai-center'>

            <span className="btn bg3 cl1 hover-bg3 hover-cl1 m0" onClick={ e => retirer(e, id) }>{ t('t71') }</span>

            <span className='btn bg2 cl1 ml10' onClick={ e => navigate(-1) }>{ t('annuler') }</span>

          </div>

        </div>

      </div>

      <Footer/>
      
  </div>

  )
}

export default RetirerProduit