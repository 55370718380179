import React, { useContext, useEffect, useState } from 'react'
import { APP_NAME, WEBROOT } from '../../constants/general'
import { Header, HeaderBox , Footer, HeaderBoutique } from '../../components/imports'
import { Link, useNavigate } from 'react-router-dom'
import { Aside } from '../../components/Aside'
import { Context } from '../../class/Context'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Tooltip
} from 'chart.js'

import { Bar, Line } from 'react-chartjs-2'
import Func from '../../class/Func'
import { useTranslation } from 'react-i18next'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Tooltip
)

function Statistiques() {

  const {t} = useTranslation()

  const navigate = useNavigate()

  const context = useContext(Context)

  const [loading, setLoading] = useState(true)

  const [dateStart, setDateStart] = useState(false)
  const [dateEnd, setDateEnd] = useState(false)

  const [lastMonth, setLastMonth] = useState({})
  const [last6Month, setLast6Month] = useState({})

  const [lastMonthlabels, setLastMonthLabels] = useState([])
  const [lastMonthData, setLastMonthData] = useState([])

  const [last6Monthlabels, setLast6MonthLabels] = useState([])
  const [last6MonthData, setLast6MonthData] = useState([])

  const [ventesTotal, setVentesTotal] = useState(0)

  const options = {
    responsive: true,
    scales: {
      y: {
        ticks: {
            stepSize: 1
          }
      }
    }
  }
  

  const data = {
    labels: last6Monthlabels,
    datasets: [
      {
        label: 'Montant en DA',
        data: last6MonthData,
        backgroundColor: '#dd9270',
        borderWidth: 1
      }
    ],
  }

  const data_line = {
    labels: lastMonthlabels,
    datasets: [
      {
        label: 'Nombre de ventes',
        data: lastMonthData,
        backgroundColor: '#49822C',
        borderColor: '#49822C',
        borderWidth: 2
      }
    ],
  }
  
  const [personnaliser, setPersonnaliser] = useState(false)

  const changeDates = (e) => {
        
    e.preventDefault()

    let inputs = e.target

    setDateStart(inputs.start.value)
    setDateEnd(inputs.end.value)

    mesStats({start: inputs.start.value, end: inputs.end.value})

  }

  const mesStats = async (dates = {start: '', end: ''}) => {

    let lastMonthlabels_var = []
    let lastMonthData_var = []

    let last6Monthlabels_var = []
    let last6MonthData_var = []

    setLoading(true)
        
    let results = await Func.fetch('users/stats', dates)

    console.log(results)

    if(results.success){
      
      setLastMonth(results.last_month)
      setLast6Month(results.last_6_month)

      results.last_month.map(elem => {
        lastMonthlabels_var.push(elem.date)
        lastMonthData_var.push(elem.total)
      })

      results.last_6_month.map(elem => {
        last6Monthlabels_var.push(elem.date)
        last6MonthData_var.push(elem.total)
      })

      setLastMonthLabels(lastMonthlabels_var)
      setLastMonthData(lastMonthData_var)

      setLast6MonthLabels(last6Monthlabels_var)
      setLast6MonthData(last6MonthData_var)

      setVentesTotal(results.ventes_total)

      setLoading(false)

    }

  }

  useEffect(() => {

    Func.session(context, navigate)

    mesStats()

    document.title = t('t256') + " - " + APP_NAME
    
  }, [])

  return (
    <div>
        <Header/>

        <HeaderBox/>

        <HeaderBoutique/>

        <div className='c'>

          <div className='flex'>

            <Aside/>

            <div className='col-78'>

              <div className='flex ai-center mt80 mb30 m_mt_10'>

                <h1 className='cl8 h1'>{t('t256')}</h1>

                <span className='cl3 c_pt m_ml_10' onClick={e => setPersonnaliser(!personnaliser)}>{t('t257')}</span>

              </div>

              {

                personnaliser

                &&

                <form className='grid4 mb30 m_p10' onSubmit={e => changeDates(e) }>

                  <div className='bg6 p20'>

                    <h3 className='mb10'>{t('t258')}</h3>

                    <input type="month" name='start'/>

                  </div>

                  <div className='bg6 p20'>

                    <h3 className='mb10'>{t('t259')}</h3>

                    <input type="month" name='end'/>

                  </div>

                  <div className='bg6 p20 m_d_none'>

                    <h3 className='mb10 cl6'>{t('t260')}</h3>

                    <input type="submit" value={t('t261')} className='btn bg3 cl1 brc1solid brc3 fz08'/>

                  </div>

                  <input type="submit" value={t('t261')} className='btn bg3 cl1 brc1solid brc3 fz08 pc_d_none m_d_block m_col_25'/>

                </form>
  
              }

              {

                loading

                ?

                <div className='flex jc-center mt40 mb40'>
                  <div className="lds-dual-ring col"></div>
                </div>

                :

                <div className='m_p10'>

                  { dateStart == false && <h2 className='mb30'>{t('t262')}</h2> }

                  <div className={'grid2'}>

                    <div>

                      <h2 className='mb30'>{t('t263')} {dateStart == false && <span>({ ventesTotal } {t('t264')})</span> }</h2>

                      <Line options={options} data={data_line} />

                    </div>

                    <div>

                      <h2 className='mb30'>{t('t265')} {dateStart == false && <span>({ last6Month.length > 0 && parseInt(last6Month[0].total).toLocaleString('fr-FR') } DA)</span> }</h2>

                      <Bar options={options} data={data} />

                    </div>

                </div>
              </div>

              }

            </div>

          </div>

        </div>

        <Footer/>
    </div>
  )
}

export default Statistiques

