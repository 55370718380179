import React from 'react'
import { useTranslation } from 'react-i18next'

import { Link } from 'react-router-dom'

const Aside = () => {

  const {t} = useTranslation()

  return (
    <div> 

    <div className='aside brca mt80 sticky bg7 m20m rm'>

        <p  className='ta-center cl3 fz12 p20'>{t('t286')}</p> 
        <div class="brc3 brc1solid"></div>
        <ul>
            <li><Link to={'/boutique/' + localStorage.getItem("idU")} className='link' ><span className='icon mr10'>u</span>{t('t7')}</Link></li>
            <li> <Link to={'/commandes/0'} className='link'><span className='icon mr10'>-</span>{t('t8')}</Link></li>
            <li> <Link to={'/mesArticles'} className='link'><span className='icon mr10'>&</span>{t('t9')}</Link> </li>
            <li> <Link to={'/discussions'} className='link'><span className='icon mr10'>N</span>{t('t11')}</Link></li>
            <li> <Link to={'/modifierboutique'} className='link'><span className='icon mr10'>E</span>{t('t12')}</Link> </li>
            <li> <Link to={'/tarifs'} className='link'><span className='icon mr10'>Z</span>{t('t195')}</Link> </li>
            <li> <Link to={'/mes-notes-avis'} className='link'><span className='icon mr10'>g</span>{t('t13')}</Link></li>
            <li> <Link to={'/mes-factures'} className='link'><span className='icon mr10'>&</span>{t('t14')}</Link> </li>
            <li> <Link to={'/parametres_Boutique'} className='link'><span className='icon mr10'>r</span>{t('t15')}</Link></li>
            <li> <Link to={'/statistiques'} className='link'><span className='icon mr10'>3</span>{t('t16')}</Link> </li>
        </ul>
        
    </div>

</div>
  )
}

const AsideClient = () =>{

  const {t} = useTranslation()

  return (

  <div> 

    <div className='aside brca sticky bg7 m20m rm'>
        <p  className='ta-center cl3 p20'>{t('t286')}</p> 
        <div class="brc3 brc1solid "></div>
        <ul>
          <li><Link to={'/commandes/0'} className='link'><span className='icon mr10'>m</span>{t('t287')}</Link></li>
          <li><Link to={'/discussions'} className='link'><span className='icon mr10'>N</span>{t('t11')}</Link></li>
          <li><Link to={'/favoris'} className='link'><span className='icon mr10'>h</span>{t('t18')}</Link></li>
          <li><Link to={'/parametres'} className='link'><span className='icon mr10'>r</span>{t('t15')}</Link></li>

        </ul>
    </div>

  </div>
  )
}

export {Aside , AsideClient}