import React , { useEffect, useState } from 'react'
import {Header , HeaderBox,Footer, Cookies} from '../components/imports'
import { APP_NAME, ROOT, WEBROOT } from '../constants/general'
import { Link, json } from 'react-router-dom'
import Annonces from '../class/Annonces'
import { ArticleCategorie, ArticlePhares } from '../components/Articles'
import Categories from '../class/Categories'
import { AllCategories } from '../components/Categories'
import Func from '../class/Func'
import gla_slider from '../Json/slider.js'
import { useTranslation } from 'react-i18next'

function Home() {

  const { t } = useTranslation()
  
  const [articles, setArticle] = useState('');

  const [categories, setCategories] = useState('')

  const [cards, setCards] = useState('')

  const [allcategories, setAllCategories] = useState('')

  const dataHome = async() =>{

    const results = await Annonces.home()

    setArticle(results.articles_phars)

    setCategories(results.categories)
    
    setCards(results.cards)
    
    setTimeout(() => {
      
      new gla_slider('#gla_slider', {
        childs: 1,
        items: '.child',
        speed: 4,
        margin: 5
      })

    }, 20)

  }

  const getAllCategories = async() =>{

    const results = await Categories.getCategories()

    setAllCategories(results.data)

  }

  const getArticles = async(e, option) =>{

    const results = await Func.fetch('annonces/acceuilArticles', {option}, false);

    setArticle(results.articles)

    let current = e.target.parentNode.querySelector('.cl3')

    current.classList.remove('cl3')
    current.classList.add('cl10')

    e.target.classList.remove('cl10')
    e.target.classList.add('cl3')

  }

  useEffect(() => {

    dataHome()

    getAllCategories()

    document.title = t('title')

  }, [])

  return (

    <div className='pos-r'>

      <Header/>

      <HeaderBox/>
     
      <div className='bg1 p40'>

        {

          cards

          ?

          <div className='c flex hero'>

            <div className='bg7 pos-r col-4 flex ai-center jc-center'>

              <div className='text m_ta_center m_pb20'>
                <img src={ WEBROOT + "image/taqalid_home_image.png" } className='img' />

                <p className='mb30 mt20 fz13 cl8'>{ t('text1') }</p>
                <Link to={ t('link') } className=' btn bg3 cl1 hover-bg5 mt50'>{ t('b1') }</Link>
              </div>

            </div>
    
            <div className='col-6 flex' id='gla_slider' dir="ltr">
            {

              cards

              &&

              cards.map(card =>

                <Link to={card.link} className='child'>
                  <img src={ ROOT + "image/home/" + card.nameI }/>
                </Link>

              )


            }
            </div>

          </div>

          :

          <div className='flex jc-center mt50 mb50'>
            <div className="lds-dual-ring col"></div>
          </div>

        }

      </div>

      {/* Produits phares */}

      <div className='bg1 mt40 c pos-r'>

          <div className='flex flex-start mb30 m_mb_0'>
      
            <span className='h1 mr20 hover-cl3 cl3 m_d_block c_pt' onClick={e => getArticles(e, 'phares')}><span className='pc_d_none m_d_i_block'>|</span> { t('t1') } </span>
            <span className='h1 mr20 hover-cl3 cl10 m_d_none c_pt' onClick={e => getArticles(e, 'top')}>| { t('t2') } </span>
            <span className='h1 mr20 hover-cl3 cl10 m_d_none c_pt' onClick={e => getArticles(e, 'promo')}>| { t('t3') } </span>
            <span className='h1 mr20 hover-cl3 cl10 m_d_none c_pt' onClick={e => getArticles(e, 'recent')}>| { t('t4') }</span>
            
          </div>
          
          {

            articles

          ?

            <>
              <div className="grid5 articles mb30">
                { articles.map(element => <ArticlePhares data={element} key={element.idA} />)}
              </div>
            </>

          :

            <div className='flex jc-center mt50 mb50'>
              <div className="lds-dual-ring col"></div>
            </div>

          }

      </div>


      <div className='bg1 mt100 c pos-r m_d_none'>

        {

         categories

        ?

          <>

            { categories.map(cat => 

              <div key={cat.idC} className='mt100'>
                
                <h2 className='h1 mb30'>{cat.name}</h2>

                <div className="grid5 cards articles mb10">

                  { cat.articles.map(element => <ArticleCategorie data={element} key={element.idA} />)}

                </div>

                <Link to={"/categorie/" + cat.url} className='cl2 hover-cl3 fz11 vp'>{ t('b2') } <span className='icon'>l</span></Link>

              </div>
            )}

          </>

        :

          <div className='flex jc-center mt50 mb50'>

            <div className="lds-dual-ring col"></div>

          </div>

        }
        
      </div>

      <div className='bg1 pt100 mb80 c special'>

        <h2 className='h1 mb20'>{t('t415')}</h2>

        {

          allcategories

        ?

          <div className="grid6 m_grid2 ta-center">
            { allcategories.map(element => <AllCategories data={element} key={element.idC} />)}
          </div>

        :

          <div className='flex jc-center mt50 mb50'>

            <div className="lds-dual-ring col"></div>

          </div>

        }

      </div>

      <Footer/>

      {/* <div class="mask gla-cookies">
        
        <div class="gla_alert">

          <div class='_card'>

            <span className='fw6 ta-center mb20 d-block fz11'>Bienvenue sur Taqalid !</span>
            <p className='mb20'>{t('t455')} <Link to={'/'} className='cl3'>{t('t456')}</Link> {t('t457')}</p>
    
            <div class="gla_btns pt10">
                <span class="cl1 hover-cl3" onclick="document.querySelector('.mask').remove()">{t('t458')}</span>
            </div>

          </div>
       
        </div>
        
      </div> */}

    </div>
  )
}

export default Home