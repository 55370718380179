import React, { useEffect, useState } from 'react'
import { Header, HeaderBox , Footer } from '../../components/imports'
import Annonces from '../../class/Annonces'
import { ArticleCategorie } from '../../components/Articles';
import { AsideClient } from '../../components/Aside';
import { useTranslation } from 'react-i18next';

function Favoris() {

    const {t} = useTranslation()

    const [favoris, setFavoris] = useState("");

    const mesFav = async () => {

        const results = await Annonces.favoris()

        setFavoris(results.data)

    }

    useEffect(() => {
      
        mesFav()
       
    }, []);

  return (
    <div>
        <Header/>

        <HeaderBox/>

        <div className='mb80 c mt80 flex fav m_mt_0 m_p10'>

            <AsideClient/>

            <div className='c'>

                <div className='mb20'>

                    <h1 className='M fz2 cl8'>{t('t18')}</h1> 

                    <p className='fz11 mt20'>{favoris.length} {t('t360')}</p>

                </div>

                <div className='grid4 articles m_p0'>
                
                    {
                        favoris

                    ?

                        favoris.map(fav => <ArticleCategorie data={fav} key={fav.idA}/>)
                    
            
                    :
                        <div className='flex jc-center mt50 mb50'>
                            <div className="lds-dual-ring col"></div>
                        </div>

                    }

                </div>

            </div>

        </div>

        <Footer/>

    </div>
  )
}

export default Favoris