import React, { useState, useEffect, useContext } from 'react';

import { useNavigate } from 'react-router-dom';

import { Footer, Header } from '../../components/imports'

import { APP_NAME } from '../../constants/general'

import { Context } from '../../class/Context'

import Func from '../../class/Func'

import { DiscussionsCard } from '../../components/chat';
import { useTranslation } from 'react-i18next';

function Discussions() {

    const {t} = useTranslation()

    const navigate = useNavigate();

    const context = useContext(Context)

    useEffect(() => {

        Func.session(context, navigate)

        document.title = t('t285') + " - " + APP_NAME

    }, [])


    return (

        <div>

            <Header />
                
            <div className="content dashboard">
                
                <div className="flex c">

                    <DiscussionsCard />

                    <div className="col-7">
                        
                        <div className='messages bg3 flex ai-center jc-center p20 br20 mt20 mb40'>
                            
                            <p className='cl1'>{t('t403')}</p>

                        </div>

                    </div>

                </div>
            </div>

        </div>

    )

}

export default Discussions