import React from 'react'
import { APP_NAME } from '../../constants/general'
import { Header, HeaderBox , Footer, HeaderBoutique } from '../../components/imports'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import Func from '../../class/Func'
import { useContext } from 'react'
import { Context } from '../../class/Context'
import { useState } from 'react'
import wilaya from '../../Json/wilaya.json'
import User from '../../class/User'
import { Aside } from '../../components/Aside'
import { useTranslation } from 'react-i18next'

function Tarifs() {

  const {t} = useTranslation()

  const navigate = useNavigate()

  const context = useContext(Context)

  const [error, setError] = useState('')

  const [infos, setInfos] = useState('')

  const [domicile, setDomicile] = useState('')

  const [pointRelais, setpointRelais] = useState('')

  const [city, setCity] = useState('')

  const [moimeme, setMoimeme] = useState(false)

  const [flash, setFlash] = useState('')
  
  const [loading, setLoading] = useState(false) 

  const modifier = async (e) =>{

    e.preventDefault()

    setLoading(true)

    let inputs = e.target

    const results = await User.tarif(inputs)

    setLoading(false)

    if(results.success){

      let t_msg = t('t271')

      if(results.message = 'm2') t_msg = t('t272')

      setFlash({type: 'succes', message: t_msg})

    }else{

      setFlash({type: 'error', message: results.message})

    }

    window.scrollTo(0, 0)

  }

  const myInfos = async () => {

    let results = await User.session(2)

    if(results.success){

      setInfos(results.data)

    }

  }

  const getTarifs = async() =>{

    let input = document.querySelector('.wilayaId').value

    if (input == 0){ 

      return false

    }

    setCity(input)

    setLoading(true)

    const results = await User.getTarif(input)

    setLoading(false)

    if(results.success){

      setDomicile(results.dom)

      setpointRelais(results.relais)

      if(results.moimeme == "1") setMoimeme(true)
      if(results.moimeme == "0") setMoimeme(false)

    }else{

      setDomicile(0)

      setpointRelais(0)

      setMoimeme(false)
      
    }

  }

  const moiMemeChange = (e) => {

    if(e.checked){
      setMoimeme(true)
    }else{
      setMoimeme(false)
    }

  }

  useEffect(() => {

    Func.session(context, navigate)

    myInfos()

    document.title = t('t195') + " - " + APP_NAME
    
  }, [])

  return (
    
    <div>
        <Header/>

        <HeaderBox/>
        
        <HeaderBoutique/>

        <div className='c mb20'>

          <div className='flex m_p10'>

            <Aside/>

            <div className='mt80 p30 cc brca mb30 m_p10' id='article'>

              {

                !loading

              ?
                <form method="POST" className="form" onSubmit={(e) => modifier(e)}>
                
                  <h1 className='ff fz18 mb10'>{t('t195')}</h1>
                  <p className='mb50'>{t('t273')}</p>

                  {(flash !== '') && <p className={ flash.type }>{ flash.message }</p>}

                  <div className='grid3'>

                    <label> <span className='d-block mb10'>{t('t196')}</span>  

                      <select type="text" name="wilaya" required="true" className='bg7 wilayaId' onChange={(e) => getTarifs()} defaultValue={city}>
                
                        <option value={0}>{t('t274')}</option>
                        
                        {
                    
                          wilaya.map((getwilaya, index)  => (

                            <option value={getwilaya.id} key={index}>{getwilaya.name}</option>

                          ))

                        }
                  
                      </select>
                    </label>

                    {  
                      
                      infos.livraison !== "0" && moimeme !== true

                     ?

                      <label>{t('t275')}
                        <input type="text" name="relais" placeholder="Ex : 300 DA" required="true" className='bg7 mt10' defaultValue={pointRelais}/>
                      </label>

                      :

                      <input type="text" name="relais" placeholder="Ex :300 DA" required="true" className='bg7 mt10 dnone' value="/"/>

                    }

                      <label>{t('t276')}
                        <input type="text" name="dom" placeholder="Ex :400 DA" required="true" className='bg7 mt10' defaultValue={domicile} />
                      </label>

                  </div>

                  <div className='flex flex-start ai-center m_flex'>
                    <input type="checkbox" name='moimeme' checked={moimeme} onChange={ e => moiMemeChange(e.target) }/>
                    <p className='mb20'>{t('t277')}</p>
                  </div>

                  <div className='flex-end'> 
                    <input type="submit" name="save" value={t('enr')} className="btn bg3 cl1 hover-bg2 hover-cl1"/>
                  </div>
        
                </form>
              :
                <div className='flex jc-center mt40 mb40'>
                  <div className="lds-dual-ring col"></div>
                </div>
              }

            </div>

          </div>

        </div>

    <Footer/>
    
    </div>
  )
}

export default Tarifs

