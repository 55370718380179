import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Footer, Header, HeaderBox } from '../../../components/imports'
import Func from '../../../class/Func';
import { APP_NAME } from '../../../constants/general';
import { useTranslation } from 'react-i18next';

function Supprimer() {

  const { t } = useTranslation()
  
  const id = useParams()

  const navigate = useNavigate()

  const supprimer = async () => {
    
    const results = await Func.fetch("annonces/supprimerAnnonce", {id: id.id})

    if(results.success){

      Func.alert(t('t39'))

      navigate(-1)

    }else{

      Func.alert("ERROR.")

    }

  }

  useEffect(() => {

    document.title = t('t40') + " - " + APP_NAME

    window.scrollTo(0, 0)

  }, []);

  return (
    <div>

      <Header/>

      <HeaderBox/>

      <div className='c flex ai-center jc-center mb30'>

        <div className='brca mt50 mb50 p30'>

          <h1 className='ff fz18 mb30'>{ t('t41') }</h1>

          <div className='flex jc-center mb30'>

            <span className="btn bg3 cl1 hover-bg2 hover-cl1" onClick={() => supprimer()}>{ t('oui') }</span>
            <span className="btn bg3 cl1 hover-bg2 hover-cl1" onClick={() => navigate(-1)}>{ t('non') }</span>

          </div>

          <p>{ t('t44') }</p>

        </div>

      </div>
      
      <Footer/>
  </div>
  )
}

export default Supprimer