import React, { useEffect, useState } from 'react'
import {Header , HeaderBox,Footer} from '../components/imports'
import { APP_NAME, WEBROOT } from '../constants/general'
import { Link , useParams } from 'react-router-dom'
import Categories from '../class/Categories'
import { AllCategories } from '../components/Categories'
import Annonces from '../class/Annonces'
import { ArticleCategorie } from '../components/Articles'
import { useTranslation } from 'react-i18next'

function Occasion() {

  const {t} = useTranslation()

  const {url} = useParams()

  const [annonces, setAnnonces] = useState('')

  const getArticles = async () => {

    let results = await Annonces.annoncesByOccasion(url)
    
    if(results.success){
      
      setAnnonces(results.annonces)

    }else{

      setAnnonces(results.message)

    }
    
  }
 
  useEffect((url) => {

    getArticles()

    document.title = "Occasion - " + APP_NAME
    
  }, [url])

  return (
    <div className='pos-r'>

      <Header/>

      <HeaderBox/>
      
        <div className='bg1 mt30 mb30 c pos-r'>

          {/* <h1 className='fz15 M fw5 cl8'>Trouvez l'article de vos rêves</h1> */}

          {

          annonces

          ?

          <>

            {

              typeof annonces == 'object'

              ?

              <div className="grid5 cards mb30 articles">

                { annonces.map(element => <ArticleCategorie data={element} key={element.idA} />)}
              
              </div>

              :

              <p>{t('t194')}</p>

            }

          </>

          :

          <div className='flex jc-center mt50 mb50'>
              <div className="lds-dual-ring col"></div>
          </div>

          }


        </div>
 
      <Footer/>

    </div>
  )
}

export default Occasion